import React from 'react';
import Header from './Header';
const VidaPersonal = () => {
    return (
        <Header link={'/img/homepage-header.jpg'}>
            <div className="header-body uk-text-center">
                <h1>Vida Personal</h1>
            </div>
            
        </Header>
    );
};

export default VidaPersonal;