import React, { useEffect, useCallback, useState } from "react";

import { animated, useSpring } from "react-spring";
import "./useFormSlider.css";



export default function useFormSlider(steps) {
  const [index, setIndex] = useState(0);

  const [stateSpring, set] = useSpring(
    () =>
      ({
        transform: "translateX(0%)",
      })
  );

  const slideDistance = 100 / steps;
  const slideWidth = Math.round(100 / steps);

  useEffect(() => {
    set({ transform: `translateX(-${slideDistance * index}%)` });
  });

  const clickGoNext = useCallback(() => {
    setIndex(index + 1);
  }, [index]);

  const clickGoBack = useCallback(() => {
    setIndex(index - 1);
  }, [index]);

  const Slider = ({ children, className }) => (
    <FormSlider
      width={steps * 100}
      className={className}
      style={stateSpring}
      slides={slideWidth}
    >
      {children}
    </FormSlider>
  );

  return {
    FormSlider: Slider,
    setIndex,
    index,
    stateSpring,
    clickGoNext,
    clickGoBack,
  };
}

function FormSlider(props) {
  const { children, style, slides, className, width } = props;
  return (
    <animated.div
      style={{
        ...style,
        "--slides": `${slides}%`,
        "--width": `${width}%`,
      }}
      className={`sliderForm ${className && className}`}
    >
      {children}
    </animated.div>
  );
}
