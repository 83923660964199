import React from "react";
import Form from "./CC/CCFormulario";
import "../css/CalculadoraCalorias.css";
import useCC from "../hooks/useCC";
const CalculadoraCalorias = () => {
  const { setCalories, CC } = useCC();
  return (
    <div className="uk-child-width-expand@s cc-container" uk-grid="true">
      <div className="cc-result uk-flex uk-flex-middle uk-flex-center">
        {CC.calories > 0 ? (
          <div style={{ backgroundImage: "url(/img/CC-bg.jpg)" }}>
            <h1 className="title">Sus calorias necesarias al dia son</h1>

            <h1 className="title">{Math.round(CC.calories)}</h1>

            <div className="uk-child-width-expand@s" uk-grid="true">
              <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <img width="200px" src="/img/bread.svg" />
                <h2 className="title">Hidratos de carbono</h2>
                <h2>{Math.round(CC.hc)}g</h2>
              </div>
              <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <img width="200px" src="/img/avocado.svg" />
                <h2 className="title">Grasas</h2>
                <h2>{Math.round(CC.fat)}g</h2>
              </div>
              <div className="uk-flex uk-flex-center uk-flex-middle uk-flex-column">
                <img width="200px" src="/img/egg.svg" />
                <h2 className="title">Proteínas</h2>
                <h2>{Math.round(CC.proteins)}g</h2>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ backgroundImage: "url(/img/CC-bg.jpg)" }}>
            <h1 className="title">Calcule sus calorias diarias necesarias!</h1>
          </div>
        )}
      </div>
      <div className="cc-form uk-flex uk-flex-middle uk-flex-center">
        <Form setCC={setCalories} />
      </div>
    </div>
  );
};

export default CalculadoraCalorias;
