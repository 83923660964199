import React from 'react';
import '../css/Navbar.css'
import TopNavbar from  './navbar/TopNavbar'
import BottonNavbar from './navbar/BottonNavbar';

const Navbar = () => {
    return (
        <React.Fragment>
            <TopNavbar/>
            <BottonNavbar/>
        </React.Fragment>
        
    
    );
};

export default Navbar;