import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const ShortAboutMe = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  return (
    <div
      className="short-about-me uk-child-width-expand@s uk-text-center uk-flex uk-flex-middle uk-flex-center"
      style={{ backgroundImage: "url('/img/shortaboutme-image.jpg')" }}
      uk-grid="true"
    >
      {/* <div className="short-about-me-seemore uk-background-cover " data-aos-offset="10" data-aos="fade-down"   data-aos-anchor-placement="top-center">
                    <img className="uk-width-expand" src="img/shortaboutme-profile-image.jpg"/>
                    <div className="short-about-me-seemore-inside uk-flex uk-flex-middle uk-flex-center">
                        <div className="uk-text-center">
                            <h1 style={{color:"white"}}>K-rel Cornejo</h1>
                            <a class="uk-button uk-button-primary button-primary">Sobre mi</a>
                        </div>
                    </div>
                </div> */}

      <div
        data-aos="fade-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
      >
        <h1>Sobre Mi</h1>

        <p>
          Hola, permiteme contarte mi experiencia... En mi niñez tuve trastornos
          alimenticios, abuse de la comida basura llevándome a tener obesidad a
          mis 8 años. En mi adolescencia empecé a entrenar por hobbies y mi
          disciplina me llevo a competir en deportes de fuerza, convirtiéndose
          en mi gran pasión el rendimiento deportivo, el entrenamiento de la
          fuerza y la nutrición. Es por estas razones que te quiero compartir
          mis conocimientos de entrenamiento, nutrición y psicología a través de
          la experiencia y estudios que he realizado para que los utilices en tu
          vida diaria y no cometas los típicos errores.
        </p>

        <p>
          Todo esto me ha llevado al lugar donde estoy ahora, ser entrenador
          personal en Barcelona, pero también ser entrenador online para llegar
          a gente en todo el mundo. Mi objetivo es concientizar a las personas
          de que con un cambio de actitud se puede lograr mejorar la capacidad
          física, emocional e influir significativamente en el ámbito
          profesional ya que la mente es el motor que nos ayuda a conseguir
          nuestros objetivos.
        </p>

        {/* 
                <div className="uk-width-expand uk-flex uk-flex-center ">
                    <a class="uk-button uk-button-primary button-primary large-button" style={{width:"200px",height:"50px"}}>Ver mas</a>
                </div>
                 */}
      </div>
    </div>
  );
};

export default ShortAboutMe;
