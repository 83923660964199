import React from "react";
import "../css/SportsPsychology.css";
import PlatformButton from "./PlatformButton";
const SportsPsychology = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <div
        className="ss-title uk-text-center"
        style={{ backgroundImage: "url(/img/sports-psychology-bg.jpg)" }}
      >
        <div className="ss-title-content" data-aos="zoom-in">
          <img src="/img/brain.png" data-aos="zoom-in" />
          <h1 className="title">SPORTS PSYCHOLOGY</h1>
        </div>
      </div>
      <div
        className="ss-content uk-text-center uk-child-width-1-2@m"
        uk-grid="true"
      >
        <div data-aos="fade-right">
          <img src="/img/artificial-intelligence.svg" />
          <h2>
            Realizar un deporte siempre ha sido una actividad humana que
            favorece el bienestar fisico y mental de las personas. Pero ¿Qué
            pasa cuando tu mente te traiciona y genera pensamientos negativos
            haciendo caer en un abismo emocional?
          </h2>
        </div>

        <div data-aos="fade-left">
          <img src="/img/productivity.svg" />
          <h2>
            Las personas productivas tienen características muy específicas en
            cuanto a cómo afectan la mente, por lo que se manejan altos niveles
            de estrés. La tarea puede quedarle grande a cualquiera si no es lo
            suficientemente efectivo en la aplicación de estrategias
          </h2>
        </div>
        <div data-aos="fade-right">
          <img src="/img/goal.svg" />
          <h2>
            Para incrementar tu potencial y alcanzar tus objetivos depende de
            tus características individuales como tu personalidad, muy ligada a
            tu biología.
          </h2>
        </div>
        <div data-aos="fade-left">
          <img src="/img/fit.svg" />
          <h2>
            Es por esto que FITANDMINDSET ha diseñado e implementado una serie
            de estrategias y técnicas que permiten mejorar el rendimiento
            personal a nivel productivo, deportivo y psicológico.
          </h2>
        </div>
        <div className="ss-quote uk-width-expand">
          <div className="">
            <h2>
              "No se puede enseñar nada a un hombre; solo se le puede ayudar a
              encontrar la respuesta dentro de si mismo."
            </h2>
            <p>
              <em>Galileo Galilei</em>
            </p>
          </div>
          <PlatformButton />
        </div>
      </div>
    </div>
  );
};

export default SportsPsychology;
