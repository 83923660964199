import React from "react";
import Header from "./Header";
import "../css/FAQS.css";
import faqs from "../data/FAQS.json";
import PlatformButton from "./PlatformButton";
const FAQS = () => {
  return (
    <React.Fragment>
      <Header link={"/img/homepage-header.jpg"}>
        <div className="header-body uk-text-center">
          <h1>FAQS</h1>
        </div>
      </Header>

      <div className="faqs-tab" style={{ padding: "50px", margin: "0" }}>
        <div className="faq-division  ">
          <h1>FITNESS AND MINDSET COACHING</h1>
          <ul uk-accordion="multiple: true">
            {Object.keys(faqs.FandMC).map((key) => (
              <li key={key}>
                <a class="uk-accordion-title" href="#">
                  {faqs.FandMC[key].q}
                </a>
                <div class="uk-accordion-content">
                  {Object.keys(faqs.FandMC[key].a).map((i) => (
                    <p key={i}>{faqs.FandMC[key].a[i]}</p>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="faq-division ">
          <h1>PROGRAMA DE ENTRENAMIENTO FISICO</h1>
          <ul uk-accordion="multiple: true">
            {Object.keys(faqs.PEF).map((key) => (
              <li key={key}>
                <a class="uk-accordion-title" href="#">
                  {faqs.PEF[key].q}
                </a>
                <div class="uk-accordion-content">
                  {Object.keys(faqs.PEF[key].a).map((i) => (
                    <p key={i}>{faqs.PEF[key].a[i]}</p>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div className="faq-division  ">
          <h1>TEAM & CONTACTO</h1>
          <ul uk-accordion="multiple: true">
            {Object.keys(faqs.TC).map((key) => (
              <li key={key}>
                <a className="uk-accordion-title" href="#">
                  {faqs.TC[key].q}
                </a>
                <div className="uk-accordion-content">
                  {Object.keys(faqs.TC[key].a).map((i) => (
                    <p key={i}>{faqs.TC[key].a[i]}</p>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className="uk-text-center uk-flex uk-flex-center uk-flex-middle uk-flex-column"
        style={{ margin: "20px" }}
      >
        <h1 className="title">¿CÓMO EMPEZAR CON EL PROGRAMA?</h1>
        <p style={{ color: "black", textAlign: "center" }}>
          Puedes mandar tus datos en la <br /> pagina de contacto o entrar
          directamente en el siguiente enlace:
        </p>
        <PlatformButton />
      </div>
    </React.Fragment>
  );
};

export default FAQS;
