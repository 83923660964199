import React from "react";
import PlatformButton from "../PlatformButton";

const Musculacion = ({ clickGoBack }) => {
  return (
    <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/musculacion-bg.jpg" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Musculación
        </h1>
        <p>
          Cuando nuestro objetivo es musculación, no es cosa de unos meses o de
          un año. Cada persona es diferente, a unos les cuesta mas que a otros.
          No hay atajos que valgan para el crecimiento muscular, solo trabajo y
          convencimiento. (Si lo hay, esteroides, pero no los recomiendo). Si
          estás buscando llegar a tus objetivos de manera rápida y saludable no
          pases por alto lo siguiente:
        </p>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        <div className="uk-flex uk-flex-center uk-flex-column">
          <div
            className="muscle-list-wrapper uk-child-width-expand@s"
            uk-grid="true"
            style={{ backgroundImage: "url(/img/musculacion-bg-1.jpg)" }}
          >
            <div className="muscle-list  ">
              <img src="/img/protein.svg" />
              <h2 className="title">BENDITA Y ALABADA SEAN LAS PROTEINAS</h2>
              <p>
                Mas que una buena programación en tu entreno, influye mas una
                dieta con un buen porcentaje de proteínas dependiendo de tus
                necesidades. Si para la OMS una persona estándar necesita
                alrededor de 0,8 gramos por kg de peso corporal, una persona que
                pretenda crecer debe estar alrededor de 1,5-2 gr por kg de peso
                corporal.{" "}
              </p>
            </div>
            <div className="muscle-list ">
              <img src="/img/gains.svg" />
              <h2 className="title">No brain, No gainz</h2>
              <p>
                Un buen entreno consiste en estimular tu sistema muscular sin
                alterar de mas tu sistema nervioso además de evitar lesiones y
                fortalecer tu sistema inmune. Si necesitas asesoramiento en tu
                entreno, te recomendamos el plan
              </p>
            </div>
          </div>

          <div
            className="muscle-list-wrapper uk-child-width-expand@s"
            uk-grid="true"
            style={{ backgroundImage: "url(/img/musculacion-bg-2.jpg)" }}
          >
            <div className="muscle-list ">
              <img src="/img/sweet-dreams.svg" />
              <h2 className="title">Sweet Dreams</h2>
              <p>
                Si los músculos se estimulan con entrenamiento, se nutren con
                una buena dieta y crecen mientras duermen de forma adecuada. Si
                quieres maximizar tu mejora de composición corporal mínimo 8
                horas de sueño mínimo son fundamentales. Las hormonas que
                desarrollan el crecimiento muscular tienen un papel importante
                en el ciclo del sueño, y el mal dormir nos hace comer mal,
                deteriorando la salud.
              </p>
            </div>
            <div className="muscle-list ">
              <img src="/img/cardio.svg" />
              <h2 className="title">Cardio: Amor/Odio</h2>
              <p>
                El entrenamiento cardiovascular se debe adaptar a tu
                entrenamiento y se debe tener en cuenta tu somatotipo y
                genética. Puedes adaptarte al entrenamiento HIIT o si prefieres
                caminar, aprovecha para escuchar un audiolibro o un podcast de
                interés.
              </p>
            </div>
          </div>

          <div
            className="muscle-list  uk-width-expand"
            style={{ backgroundImage: "url(/img/musculacion-bg-3.jpg)" }}
          >
            <img src="/img/suplement.svg" />
            <h2 className="title">Suplementación</h2>
            <p>
              Si lo deseas o no cumples con tus necesidades nutricionales te
              recomiendo <br /> usar los siguientes suplementos que son los mas
              respaldados por la ciencia:
            </p>

            <ul>
              <li>Pre-Workout: Cafeina</li>
              <li>Whey Protein</li>
              <li>Creatina HCL</li>
              <li>Melatonina</li>
            </ul>
          </div>
        </div>
        <div
          className="musculacion-platform uk-width-expand"
          style={{ backgroundImage: "url(/img/muscle-platform-bg.jpg)" }}
        >
          <h1 className="title">
            ¿Me permites informarte sobre como hacer bien las cosas?
          </h1>
          <PlatformButton />
        </div>
      </div>
    </div>
  );
};

export { Musculacion };
