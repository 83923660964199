import React, { useState } from "react";
import "../css/Switcher.css";
const Switch = ({ size, onChange, color, labelText }) => {
  const [checked, setChecked] = useState(true);

  const verify = () => {
    setChecked(!checked);

    onChange();
  };
  const styles = {
    "--lbl-height": `${size}px`,
    "--lbl-width": `${size * 2}px`,
    "--lbl-after-width": `${size - 10}px`,
    "--lbl-after-height": `${size - 10}px`,
    "--lbl-active-after": `${size + 8}px`,
    "--color": color,
  };
  return (
    <div className="uk-flex uk-flex-middle uk-flex-center">
      <div className="uk-text-center">
        <h3 style={{ margin: "10px 0" }}>{labelText}</h3>
        <div className="uk-flex uk-flex-middle uk-flex-center switcher">
          <input
            type="checkbox"
            checked={checked}
            onChange={verify}
            id="switch"
          />
          <label style={styles} for="switch">
            Toggle
          </label>
        </div>
      </div>
    </div>
  );
};

export default Switch;
