import React from "react";
import "../../css/dietas/DietaPaleo.css";
import PlatformButton from "../PlatformButton";

const DPaleo = ({ clickGoBack }) => {
  return (
    <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/dieta-paleo.jpg" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Dieta Paleo
        </h1>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        <div
          className="dpaleo-intro"
          style={{ backgroundImage: "url(/img/dpaleo-intro-bg.jpg)" }}
        >
          <h1 className="title">¿Que es?</h1>
          <p>
            En la alimentación, como en la vida, muchas veces,
            <b>lo más sencillo es lo más efectivo</b>. Se basa en el consumo de
            los alimentos disponibles en la naturaleza{" "}
            <b>antes de la Revolución del Neolítico</b>, la agricultura y el
            inicio del sedentarismo, cuando nuestros antepasados cazaban y
            recolectaban.
          </p>

          <p>
            La dieta paleo, paleolítica o dieta del hombre de las cavernas nos
            lleva a la Edad de Piedra. Consiste en priorizar l
            <b>
              as carnes magras y pescados, vegetales, frutos secos y semillas
            </b>
            , alimentos que eran la base de la alimentación del hombre
            prehistórico, nómada y que aún no había desarrollado la agricultura.
            Dieta
            <b>
              alta en proteínas, los cereales, las legumbres o los lácteos no se
              permiten.
            </b>
          </p>

          <p>
            La dieta paleo propone recuperar la alimentación originaria de los
            seres humanos.
          </p>
        </div>

        <div
          className="dpaleo-benefits"
          style={{ backgroundImage: "url(/img/dpaleo-benefits-bg.jpg)" }}
        >
          <h2 className="title">BENEFICIOS</h2>
          <ul className="uk-child-width-expand@s" uk-grid="true">
            <li>
              <img src="/img/dzona-list-1.svg" />
              <p>
                Es la manera más saludable de comer, y que puede “ayudarte a
                mantenerte delgado, fuerte y lleno de energía”.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-2.svg" />
              <p>
                Puede ayudarnos a perder peso o a mantenernos en un peso
                saludable, e incluso a mejorar nuestra tolerancia a la glucosa y
                nuestra tensión arterial.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-3.svg" />
              <p>
                Es una dieta bastante restrictiva. Se elimina todo “alimento”
                procesado e industrial y se aumenta el consumo de productos
                frescos y la garantía es un estilo de vida más saludable.
              </p>
            </li>
          </ul>
        </div>

        <div
          className="dpaleo-recomend"
          style={{ backgroundImage: "url(/img/dpaleo-recomend-bg.jpg)" }}
        >
          <h2 className="title">¿TU DIETA PUEDE SER PALEO?</h2>
          <p>
            La base de una dieta de paleo debería ser vegetal y en “teoría” un
            50-70% cruda. Imagina los tiempos paleolíticos, no tenían las
            herramientas que tenemos hoy en dia.
          </p>
          <p>Aumenta el consumo de estos alimentos para ser paleo</p>
          <ul className="uk-child-width-expand@s" uk-grid="true">
            <li>
              <img src="/img/dpaleo-recomend-1.svg" />
              Proteinas (Pollo, ternera, cerdo, pescado)
            </li>
            <li>
              <img src="/img/dpaleo-recomend-2.svg" />
              Hortalizas: da prioridad a las que no necesitan cocción.
            </li>
            <li>
              <img src="/img/dpaleo-recomend-3.svg" />
              Frutas poco dulces: aportan energía sin altibajos.
            </li>
            <li>
              <img src="/img/dpaleo-recomend-4.svg" />
              Fermentados: son fáciles de digerir y favorecen la salud
              intestinal.
            </li>
            <li>
              <img src="/img/dpaleo-recomend-5.svg" />
              Semillas y frutos secos: aportan proteínas y grasas. Actívalos
              (déjalos unas horas en remojo antes de prepararlos).
            </li>
            <li>
              <img src="/img/dpaleo-recomend-6.svg" />
              Germinados: están llenos de vida y nutrientes.
            </li>
            <li>
              <img src="/img/dpaleo-recomend-7.svg" />
              Grasas saludables: Aceite de coco, Aceite de coco, Manteca de
              cerdo.
            </li>
          </ul>
        </div>

        <div
          className="dzona-platform uk-flex uk-flex-center uk-flex-middle"
          style={{ backgroundImage: "url(/img/dzona-platform-bg.jpg)" }}
        >
          <h1 className="title">
            ¿Me permites informarte sobre como hacer bien las cosas?
          </h1>
          <PlatformButton />
        </div>
      </div>
    </div>
  );
};

export default DPaleo;
