export const converTime = (time) => {
  console.log(time);
  let minutes = Math.floor(time / 60);
  let seconds = time - minutes * 60;

  if (minutes === 0) {
    minutes = "00";
  }

  if (minutes < 10 && minutes > 0) {
    minutes = `0${minutes}`;
  }

  if (seconds == 0) {
    seconds = "00";
  }

  if (seconds < 10 && seconds > 0) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const BMImetric = ({ height, weight }) => {
  return (weight / Math.pow(height / 100, 2)).toFixed(2);
};

export const BMIimperial = ({ height, weight }) => {
  return ((703 * weight) / Math.pow(height, 2)).toFixed(2);
};

export const BMItext = (bmi) => {
  if (bmi < 18.5) {
    return "BAJO PESO";
  }

  if (bmi > 18.5 && bmi < 25) {
    return "PESO NORMAL";
  }

  if (bmi > 25 && bmi < 30) {
    return "SOBREPESO";
  }

  if (bmi > 30) {
    return "OBESIDAD";
  }
};

export const BMIComentary = (bmi) => {
  if (bmi < 18.5) {
    return {
      rango: "Rango de IMC Normal: Rango inferior a 18.5 Br.",
      comentario:
        "Presentas un peso por debajo del normal y esto puede traer consigo muchas enfermedades (Cancer, Tuberculosis, Hipertiroidismo, Diabetes Tipo 1, Ansiedad) ¿Quieres aumentar masa muscular? Consulta nuestra pagina ALIMENTACION INTELIGENTE que te ayudara a lograr tu objetivo. Para cualquier cambio importante en tu dieta y régimen de entrenamiento te aconsejamos nuestro plan",
    };
  }

  if (bmi > 18.5 && bmi < 25) {
    return {
      rango: "Rango de IMC Normal: Rango entre 18.5 y 25 Br.",
      comentario:
        "Tienes un peso saludable, así que no necesitas perder o ganar peso. Si buscas mejorar tu composición corporal, consulta nuestra pagina que te ayudara a lograr tu objetivo fitness. Para cualquier cambio importante en tu dieta y régimen de entrenamiento te aconsejamos nuestro plan",
    };
  }

  if (bmi > 25 && bmi < 30) {
    return {
      rango: "Rango de IMC Normal: Rango entre 25 y 30 Br.",
      comentario:
        "Estas pasado de peso pero no te preocupes, para perder peso consulta nuestra pagina que te ayudara a mejorar tu composición corporal. Para cualquier cambio importante en tu dieta y régimen de entrenamiento te aconsejamos nuestro plan",
    };
  }

  if (bmi > 30) {
    return {
      rango: "Rango de IMC Obesidad: Rango superior a 30 Br.",
      comentario:
        "¡Ten cuidado! Tu salud esta riesgo. Consulta nuestra pagina que te ayudará a perder peso. MEJORAR COMPOSICION CORPORAL. Para cualquier cambio importante en tu dieta y régimen de entrenamiento te aconsejamos nuestro plan",
    };
  }
};

export const convertLBtoKG = (LB) => {
  return LB * 0.453592;
};

export const convertPULGtoCM = (PULG) => {
  return PULG * 2.54;
};

export const caloriesMetric = (cc) => {
  if (cc.gender == "f") {
    return (
      (10 * cc.weight + 6.25 * cc.height - 5 * cc.age - 161) * cc.activity * 1.1
    );
  } else {
    return (
      (10 * cc.weight + 6.25 * cc.height - 5 * cc.age + 5) * cc.activity * 1.1
    );
  }
};

export const caloriesImperial = (cc) => {
  const ccI = {
    weight: convertLBtoKG(cc.weight),
    height: convertPULGtoCM(cc.height),
    age: cc.age,
    gender: cc.gender,
  };
  return caloriesMetric(ccI);
};

export const calculateMacrosMetric = (objective, weight) => {
  switch (objective) {
    case 0: {
      return {
        protein: 2 * weight,
        fat: 0.9 * weight,
        c: 3 * weight,
      };
    }
    case 1: {
      return {
        protein: 1.8 * weight,
        fat: 1.2 * weight,
        c: 5 * weight,
      };
    }
    case 2: {
      return {
        protein: 1.4 * weight,
        fat: 0.8 * weight,
        c: 3 * weight,
      };
    }
  }
};

export const calculateMacrosImperial = (objective, weight) => {
  return calculateMacrosMetric(objective, convertLBtoKG(weight));
};
