import React from "react";
import "../../css/composicion-corporal/PerdidaDeGrasa.css";
import PlatformButton from "../PlatformButton";

const PerdidaDeGrasa = ({ clickGoBack }) => {
  return (
    <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/weight-loss-bg.jpg)" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Perdida de Grasa
        </h1>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        <div
          className="weight-loss-intro"
          style={{ paddingTop: "30px" }}
          style={{ backgroundImage: "url(/img/perdida-grasa-intro-bg.jpg)" }}
        >
          <p>
            Para empezar, aclaro que no es lo mismo perder peso que perder
            grasa, la industria de la belleza y el cuidado corporal hay hecho
            creer a las personas que un batido verde o “Detox”, una faja
            “reductora” o suplementos “quemadores de grasa” es el mejor camino
            para mejorar tu composición corporal. Mucho marketing para vender
            sus productos confunde a la gente sobre que es verdad y que no. «una
            mentira repetida mil veces se convierte en una verdad»
          </p>

          <p>
            Para perder grasa de una manera saludable y sostenible, no es
            probable que vea resultados de la noche a la mañana: tomará tiempo,
            dedicación y determinación. Pero puede hacerse.
          </p>

          <p>
            Pero la ciencia pone de manifiesto tres pilares fundamentales que se
            deben cumplir para perder grasa de manera saludable:
          </p>
        </div>

        <div
          className="weight-loss-content uk-child-width-expand@s"
          uk-grid="true"
        >
          <div className="weight-loss-content-text">
            <h2 className="title">Déficit calórico.</h2>
            <p>
              Básicamente comer menos de lo que gastas. Al reducir tu ingesta
              calórica fuerzas a tu cuerpo a cubrir la diferencia con sus
              reservas de grasa. Una manera de llegar al déficit es consumiendo
              solo comida real ( frutas, verduras, carne ).
            </p>
          </div>

          <div
            className="weight-loss-content-img "
            style={{
              backgroundImage: "url(/img/weight-loss-content-1.jpg)",
            }}
          ></div>
        </div>
        <div
          className="weight-loss-content  uk-child-width-expand@s"
          uk-grid="true"
        >
          <div
            className="weight-loss-content-img img-2"
            style={{
              backgroundImage: "url(/img/weight-loss-content-2.jpg)",
            }}
          ></div>

          <div className="weight-loss-content-text">
            <h2 className="title">Suficiente proteína.</h2>

            <p>
              Primero agua, después proteína, asi es el orden de los principales
              componentes del cuerpo, la proteína es el principal componente de
              nuestro cuerpo, y participa en casi todos los procesos celulares.
              Su nombre viene del griego proteios y se traduce como importancia
              primodial.
            </p>

            <p>
              En el proceso de pérdida de grasa, la proteína cumple 3 funciones
              principales.
            </p>
          </div>
        </div>

        <div
          className="weight-loss-content uk-child-width-expand@s"
          uk-grid="true"
        >
          <div className="weight-loss-content-text">
            <h2 className="title">Entrenamiento de fuerza.</h2>

            <p>
              Y el tercer gran pilar de la pérdida de grasa corporal es el
              entrenamiento de fuerza. Uno de los errores frecuentes al perder
              grasa es realizar demasiado ejercicio aeróbico y muy poco (o nulo)
              entrenamiento de fuerza.
            </p>

            <p>
              Si quieres un programa saludable de perdida de grasa de la mano de
              profesionales puedes visitar nuestra plataforma.
            </p>
          </div>

          <div
            className="weight-loss-content-img"
            style={{
              backgroundImage: "url(/img/weight-loss-content-3.jpg)",
            }}
          ></div>
        </div>

        <ul
          className=" uk-width-expand uk-child-width-expand@s uk-text-center weight-loss-function"
          uk-grid="true"
        >
          <li
            style={{ backgroundImage: "url(/img/weight-loss-function-1.jpg)" }}
          >
            <h3 className="title">Aporta saciedad</h3>
            <p>Facilitando mantener el déficit calórico.</p>
          </li>
          <li
            style={{ backgroundImage: "url(/img/weight-loss-function-2.jpg)" }}
          >
            <h3 className="title">Previene la pérdida muscular</h3>
            <p>Uno de los mayores riesgos de estar en déficit calórico.</p>
          </li>
          <li
            style={{ backgroundImage: "url(/img/weight-loss-function-3.jpg)" }}
          >
            <h3 className="title">Eleva la termogénesis.</h3>
          </li>
        </ul>

        {/*         <p>
          Para perder grasa de una manera saludable y sostenible, no es probable
          que vea resultados de la noche a la mañana: tomará tiempo, dedicación
          y determinación. Pero puede hacerse. Comienza tu mejora de composición
          corporal repleto de información algunos de nuestros productos más
          vendidos que lo ayudarán a lograr su objetivo.
        </p>

        <p>
          Cuando nuestro objetivo es musculación, no es cosa de unos meses o de
          un año. Cada persona es diferente, a unos les cuesta mas que a otros.
          No hay atajos que valgan para el crecimiento muscular, solo trabajo y
          convencimiento. (Si lo hay, esteroides, pero no los recomiendo). Si
          estás buscando llegar a tus objetivos de manera rápida y saludable no
          pases por alto lo siguiente:
        </p> */}
      </div>

      <div className="option-text uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <h1 className="title">
          ¿Me permites informarte sobre como hacer bien las cosas?
        </h1>
        <PlatformButton />
      </div>
    </div>
  );
};

export { PerdidaDeGrasa };
