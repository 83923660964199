import { useState, useEffect } from "react";
import { converTime } from "../services/helper";

import { start, endRound, warning, finish } from "../sounds";
const defaultTabata = {
  rounds: 10,
  work: 40,
  workString: "00:40",
  rest: 10,
  restString: "00:10",
  countdown: 10,
  countdownString: "00:10",
  isStarting: false,
  isStarted: false,
  isFinished: false,
};

const useTabata = (initialTabataState = defaultTabata) => {
  const [initialTabata, setInitialTabata] = useState(initialTabataState);

  const [tabata, setTabata] = useState(initialTabata);

  const startTabata = () =>
    setTabata({
      ...tabata,
      isStarting: true,
    });

  const stopTabata = () => setTabata(initialTabataState);

  const audio = new Audio();

  const playSound = (sound) => {
    audio.src = sound;
    audio.play();
  };

  useEffect(() => {
    const {
      work,
      rounds,
      rest,
      isStarted,
      isFinished,
      isStarting,
      countdown,
    } = tabata;

    if (countdown === initialTabata.countdown && isStarting) {
      playSound(start);
    }
    if (countdown <= 3 && countdown !== 0 && isStarting) {
      playSound(warning);
    }
    if (work === initialTabata.work && isStarted) {
      playSound(start);
    }
    if (
      work === 0 &&
      rest === initialTabata.rest &&
      isStarted &&
      rounds !== 1
    ) {
      playSound(endRound);
    }
    if (work <= 3 && work !== 0 && isStarted) {
      playSound(warning);
    }

    if (rest <= 3 && rest !== 0 && isStarted) {
      playSound(warning);
    }

    if (isFinished) {
      playSound(finish);
    }

    let timer;
    let t;
    if (isStarting && !isStarted) {
      if (countdown > 0) {
        t = setInterval(() => {
          setTabata({
            ...tabata,
            countdown: countdown - 1,
            countdownString: converTime(countdown - 1),
          });
          console.log("hooalaaaaa");
        }, 1000);
      }

      if (countdown === 0) {
        setTabata({
          ...tabata,
          isStarted: true,
          isStarting: false,
          countdown: initialTabata.countdown,
          countdownString: initialTabata.countdownString,
        });
      }
    } else {
      clearTimeout(t);
    }

    if (isStarted && !isFinished && !isStarting) {
      if (work > 0) {
        timer = setInterval(() => {
          setTabata({
            ...tabata,
            work: work - 1,
            workString: converTime(work - 1),
          });
        }, 1000);
      }

      if (work === 0) {
        if (rounds !== 1) {
          timer = setInterval(() => {
            setTabata({
              ...tabata,
              rest: rest - 1,
              restString: converTime(rest - 1),
            });
          }, 1000);
        }
      }

      if (rest === 0 && work === 0) {
        setTabata({
          ...tabata,
          rounds: rounds - 1,
          work: initialTabata.work,
          rest: initialTabata.rest,
          workString: initialTabata.workString,
          restString: initialTabata.restString,
        });
      }

      if (rounds === 1 && work === 0) {
        setTabata({ ...initialTabata, isFinished: true });
      }
    } else {
      clearTimeout(timer);
    }

    if (isFinished) {
      setInitialTabata(initialTabataState);
      setTabata(initialTabataState);
    }

    return () => {
      clearTimeout(timer);
      clearTimeout(t);
    };
  });

  const handleTabataChange = (timer) => {
    setInitialTabata({
      ...tabata,
      ...timer,
    });

    setTabata({
      ...tabata,
      ...timer,
    });
  };

  return {
    tabata,
    startTabata,
    stopTabata,
    handleTabataChange,
  };
};

export { useTabata };
