import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Homepage from "./Homepage";
import Navbar from "./Navbar";
import AlimentacionInteligente from "./AlimentacionInteligente";
import FAQS from "./FAQS";
import MejorarComposicionCorporal from "./MejorarComposicionCorporal";
import VidaPersonal from "./VidaPersonal.js";
import SobreMi from "./SobreMi";
import FitAndMinset from "./FitAndMindset";
import Footer from "./Footer";
import RecomendedBooks from "./RecomendedBooks";
import TabataTimer from "./TabataTimer";
import BMI from "./BMI";
import Contacto from "./Contacto";
import SportsPsychology from "./SportsPsychology";
import TarjetaDigital from "./TarjetaDigital";
import WhatsAppWidget from "./react-whatsapp-widget";
import CalculadorCalorias from "./CalculadoraCalorias";
import CalculadoraHidratacion from "./CalculadoraHidratacion";
import "./react-whatsapp-widget/dist/index.css";

const Routing = () => {
  return (
    <React.Fragment>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>

          <Route exact path="/FitAndMindset">
            <FitAndMinset />
          </Route>

          <Route exact path="/AlimentacionInteligente">
            <AlimentacionInteligente />
          </Route>

          <Route exact path="/FAQS">
            <FAQS />
          </Route>

          <Route exact path="/MejorarComposicionCorporal">
            <MejorarComposicionCorporal />
          </Route>

          <Route exact path="/VidaPersonal">
            <VidaPersonal />
          </Route>

          <Route exact path="/SobreMi">
            <SobreMi />
          </Route>

          <Route exact path="/RecomendedBooks">
            <RecomendedBooks />
          </Route>

          <Route exact path="/tabatatimer">
            <TabataTimer />
          </Route>

          <Route exact path="/BMI">
            <BMI />
          </Route>

          <Route exact path="/Contact">
            <Contacto />
          </Route>

          <Route exact path="/SportsPsychologyAndCoaching">
            <SportsPsychology />
          </Route>
          <Route exact path="/TarjetaDigital">
            <TarjetaDigital />
          </Route>

          <Route exact path="/CalculadorCalorias">
            <CalculadorCalorias />
          </Route>
          <Route exact path="/CalculadoraHidratacion">
            <CalculadoraHidratacion />
          </Route>
        </Switch>
        <WhatsAppWidget
          phoneNumber="34688392497"
          message="En que puedo ayudarte hoy?"
          textReplyTime="En pocos minutos"
          companyName="K-rel Cornejo"
          sendButton="Escribeme"
        />
        <Footer />
      </Router>
    </React.Fragment>
  );
};

export default Routing;
