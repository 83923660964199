import React from "react";
import "../css/TarjetaDigital.css";

const TarjetaDigital = () => {
  return (
    <div
      className="tarjeta-digital-wrapper uk-flex uk-flex-center uk-flex-middle"
      style={{ backgroundImage: "url(/img/tarjeta-digital-bg-wrapper.jpg)" }}>
      <div className="tarjeta-digital-container uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <div className="uk-flex header-tarjeta-digital uk-flex-center uk-flex-middle uk-flex-column">
          <div
            className="header-tarjeta-digital-bg"
            style={{
              backgroundImage: "url(/img/tarjeta-digital-bg.jpg)",
            }}></div>
          <div
            className="uk-flex header-tarjeta-digital-floating  uk-flex-center uk-flex-middle uk-flex-column"
            style={{ backgroundImage: "url(/img/pp.jfif)" }}></div>
        </div>
        <main className="main-tarjeta-digital uk-flex uk-flex-top uk-flex-middle uk-flex-column">
          <h1 className="tarjeta-digital-title title uk-text-center">
            K-rel Cornejo
          </h1>
          <h2 className="tarjeta-digital-sub-title">Fitness Trainer</h2>
          <div className="contact uk-child-width-expand uk-flex uk-flex-center uk-flex-middle">
            <div>
              <a
                className="fab fa-whatsapp fa-3x"
                target="_blank"
                href="https://api.whatsapp.com/send?phone=34623233139"></a>
            </div>
            <div>
              <a
                className="fab fa-facebook fa-3x"
                target="_blank"
                href="https://www.facebook.com/krelcornejo-2228626270734526"></a>
            </div>
            <div>
              <a
                className="fab fa-twitter fa-3x"
                target="_blank"
                href="https://twitter.com/KrelCornejo"></a>
            </div>
            <div>
              <a
                className="fab fa-instagram fa-3x"
                target="_blank"
                href="https://instagram.com/KrelCornejo"></a>
            </div>
            <div>
              <a
                className="fab fa-telegram-plane fa-3x"
                target="_blank"
                href="https://telegram.me/KrelCornejo"></a>
            </div>
          </div>
          <div className="about-me uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <h1 className="title">Sobre mi</h1>
            <p>
              Mi nombre es K-rel Cornejo, en mi niñez tuve trastornos
              alimenticios, abuse de la comida basura llevándome a tener
              obesidad a mis 8 años. En mi adolescencia empecé a entrenar por
              hobbies y mi disciplina y talento me llevo a competir en deportes
              de fuerza, convirtiéndose en mi gran pasión el rendimiento
              deportivo, el entrenamiento de la fuerza y la nutrición.
            </p>
          </div>
          <div className="direct-contact uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <h1 className="title">Contactame</h1>
            <a>
              <span className="direct-contact-icon fas fa-phone-alt "></span>+34
              623 233 139
            </a>
            <a>
              <span className="direct-contact-icon fas fa-envelope "></span>
              info@k-relcornejo.com
            </a>
          </div>
        </main>
      </div>
    </div>
  );
};

export default TarjetaDigital;
