import React from "react";
import "../../css/composicion-corporal/HabitosSaludables.css";
import PlatformButton from "../PlatformButton";

const HabitosSaludables = ({ clickGoBack }) => {
  return (
    <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/hs-bg.jpg" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Habitos Saludables
        </h1>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        <div
          className="hs-intro"
          style={{ backgroundImage: "url(/img/hs-intro-bg.jpg)" }}
        >
          <h1 className="title">
            Una vida sana se basa en hábitos saludables que nos ayudan a
            sentirnos mejor física y psicológicamente. Te comparto estos hábitos
            fáciles que en realidad parecen sencillos, pero a veces cuesta
            cumplirlos.
          </h1>
        </div>

        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-1.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Beber como mínimo 2 litros de agua al día</h2>
            <p>
              El <b>musculo</b> más importante que tienes es el <b>cerebro</b>,
              para su <b>mejor funcionamiento</b> requiere estar
              <b>bien hidratado</b>, ya que la composición de este es mayormente
              agua. Toma consciencia y lleva contigo siempre una botella con
              agua.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-2.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Comer diariamente fruta, verdura y hortalizas</h2>
            <p>
              Recomiendo el consumo de <b>alimentos naturales</b> y olvidar
              todos los “alimentos” procesado. Además, tu cuerpo te lo
              agradecerá y te sentirás con más <b>vitalidad</b> y serás más
              <b>productivo</b>.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-3.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Estar en constante movimiento</h2>
            <p>
              La naturaleza del ser humano es el <b>movimiento</b>, no estar
              sentado. Además de los más de 30 minutos de actividad física
              diaria, hay que tener una vida <b>activa</b> y moverse durante
              todo el día: evitar el ascensor, dejar el coche e ir en bicicleta
              o caminando (en la medida de lo posible), escucha algo de música,
              podcasts y <b>muévete</b>.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-4.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Mantener la motivación</h2>
            <p>
              Ten en mente que eres la única persona que puedes hacer lo que te
              propones, que nadie más lo hará por ti. <b>Enfócate</b> en tus
              objetivos y <b>trabaja</b> siempre <b>inteligentemente</b>. Corta
              de raíz con todas las cosas/personas que no te aportan
              positividad.
            </p>
            <p>
              La mente en su propio lugar, y en sí mismo puede hacer un cielo de
              los infiernos, un infierno de los cielos. – John Milton
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-5.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Evitar las malas posturas</h2>
            <p>
              Perjudican nuestra salud, generando problemas como dolores o
              contracturas. Cuando estamos mucho rato sentados o mucho rato de
              pie, sobrecargamos los músculos y es cuando nos aparecen los
              molestos dolores.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-6.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Evita el alcohol y el tabaco</h2>
            <p>
              <b>Perjudican</b> diferentes sistemas de nuestro cuerpo y
              presentan un <b>riesgo</b> para nuestra salud.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-7.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Descansar entre 6 a 8 horas diarias</h2>
            <p>
              Un buen dormir nos brinda estar más descansados y
              <b> tener energía </b>
              durante todo el día. Si tienes mal dormir, te aconsejo tomar
              infusiones relajantes y hacer una siesta de 20 minutos por la
              tarde.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img
          "
            style={{ backgroundImage: "url(/img/hs-bg-8.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Conocer tus requerimientos calóricos y nutricionales</h2>
            <p>
              No importa cuantos tiempos de comida realices, lo realmente
              <b> importante </b> es no pasarse y ni consumir menos que nuestras
              <b> necesidades </b>.
            </p>
          </div>
        </div>
        <div
          className="hs-values uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle"
          uk-grid="true"
        >
          <div
            className="hs-values-img uk-heigth-expand
          "
            style={{ backgroundImage: "url(/img/hs-bg-9.jpg)" }}
          ></div>
          <div class="hs-values-content">
            <h2>Evita las grasas saturadas</h2>
            <p>
              Podemos permitirnos un capricho de vez en cuando, pero no siempre.
              <b>Aprende cuando tu hambre sea emocional o racional</b>.
            </p>
          </div>
        </div>

        <div
          class="hs-outro"
          style={{ backgroundImage: "url(/img/hs-outro-bg.jpg)" }}
        >
          <h2 className="title">
            Te animo a que sigas, en la medida de lo posible, estos hábitos
            saludables. Al principio cuesta un poco seguirlos, pero con
            constancia pasarán de ser obligaciones a hábitos.
          </h2>
          <h2 className="title">
            El camino hacia el éxito está siempre en construcción. Es un proceso
            permanente y no una meta que se deba alcanzar.
          </h2>

          <h2 className="title">
            ¿Me permites informarte sobre como hacer bien las cosas?
          </h2>
          <PlatformButton />
        </div>
      </div>
    </div>
  );
};

export { HabitosSaludables };
