import React from "react";

const BMIIntro = () => {
  return (
    <div className="bmi-intro-wrapper ">
      <div
        className="bmi-intro-content uk-flex uk-child-width-expand@s"
        uk-grid="true">
        <div className="bmi-intro-content-text">
          <div
            className=" bmi-intro-title uk-text-center"
            data-aos="zoom-in-right" data-aos-once="true">
            <h1 className="title ">Que es el IMC?</h1>
          </div>

          <div className="bmi-text uk-text-justify" data-aos="zoom-in-right" data-aos-once="true">
            <p>
              El índice de masa corporal, o IMC, es una medida utilizada para
              determinar si estás dentro de un rango de peso saludable para tu
              altura. El IMC se utiliza a menudo para ayudar a los profesionales
              médicos a evaluar el riesgo de enfermedades crónicas en un
              paciente. Ten en cuenta: tu IMC funciona únicamente con el peso y
              no puede decirte si tienes demasiada grasa, ya que no puede
              diferenciar entre el exceso de grasa, músculo o hueso.
            </p>
          </div>
        </div>

        <div
          className="bmi-intro-content-img-right"
          style={{ backgroundImage: "url(/img/BMI-1.jpg)" }}></div>
      </div>

      <div
        className="bmi-intro-content uk-flex uk-child-width-expand@s"
        uk-grid="true">
        <div
          className="bmi-intro-content-img-left"
          style={{ backgroundImage: "url(/img/BMI-2.jpg)" }}></div>
        <div className="bmi-intro-content-text">
          <div
            className="title bmi-intro-title uk-text-center"
            data-aos="zoom-in-right" data-aos-once="true">
            <h1 className="title ">Entiende tu IMC:</h1>
          </div>

          <div className="bmi-text uk-text-justify" data-aos="zoom-in-right" data-aos-once="true">
            <p>
              En lugar de preocuparse por los números de la balanza. Hemos usado
              tu altura y peso para calcular tu índice de masa corporal (IMC)
              para averiguar en qué rango de peso encajas.
            </p>
            <p>
              25 a 29.9- La categoría de sobrepeso. Puede tener exceso de grasa
              o una gran cantidad de músculo. Siempre es mejor construir hábitos
              saludables, como comer saludablemente y ser más activo.
            </p>
            <p>
              30+ - La categoría de obesidad. Puede haber algunas razones por
              las que te encuentras en esta categoría, intenta asegurarte de que
              llevas un estilo de vida saludable comiendo de forma saludable y
              estando activo.
            </p>
          </div>
        </div>
      </div>

      <div
        className="bmi-intro-content uk-flex uk-child-width-expand@s"
        uk-grid="true">
        <div className="bmi-intro-content-text ">
          <div
            className="title bmi-intro-title uk-text-center"
            data-aos="zoom-in-right" data-aos-once="true">
            <h1 className="title ">Rangos de IMC.</h1>
          </div>

          <div className="bmi-text uk-text-justify" data-aos="zoom-in-right" data-aos-once="true">
            <p>
              <b>Por debajo de 18.5</b> - Estás en la categoría de peso
              insuficiente. Esto podría ser una señal de que no estás comiendo
              lo suficiente o indicar posibles condiciones médicas subyacentes.
              <br /> Si necesitas ayuda <b>18.5 a 24.9</b> - Este es el rango de
              peso saludable. ¡Sigue así!
            </p>
            <p>
              <b>25 a 29.9</b>- La categoría de sobrepeso. Puede tener exceso de
              grasa o una gran cantidad de músculo. Siempre es mejor construir
              hábitos saludables, como comer saludablemente y ser más activo.
            </p>
            <p>
              <b>30+</b> - La categoría de obesidad. Puede haber algunas razones
              por las que te encuentras en esta categoría, intenta asegurarte de
              que llevas un estilo de vida saludable comiendo de forma saludable
              y estando activo.
            </p>
          </div>
        </div>
        <div
          className="bmi-intro-content-img-right"
          style={{ backgroundImage: "url(/img/BMI-3.jpg)" }}></div>
      </div>

      <div className="bmi-intro-content uk-child-width-expand@s" uk-grid="true">
        <div
          className="bmi-intro-content-img-left"
          style={{ backgroundImage: "url(/img/BMI-4.jpg)" }}></div>
        <div className="bmi-intro-content-text">
          <div
            className=" bmi-intro-title uk-text-center"
            data-aos="zoom-in-right" data-aos-once="true">
            <h1 className="title ">Precisión del IMC:</h1>
          </div>

          <div className="bmi-text uk-text-justify" data-aos="zoom-in-right" data-aos-once="true">
            <p>
              El IMC toma en consideración tu altura y peso para ubicarlo en un
              rango de peso. Sin embargo, la calculadora no puede diferenciar
              entre músculo y grasa. Por lo tanto, si tienes una gran cantidad
              de músculos puedes haber sido colocado en la categoría de
              sobrepeso u obesidad.
            </p>

            <p>
              El IMC toma en consideración tu altura y peso para ubicarlo en un
              rango de peso. Sin embargo, la calculadora no puede diferenciar
              entre músculo y grasa. Por lo tanto, si tienes una gran cantidad
              de músculos puedes haber sido colocado en la categoría de
              sobrepeso u obesidad.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BMIIntro };
