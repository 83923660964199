import React, { useState } from "react";
import { converTime } from "../../services/helper";
const TimerForm = ({
  tabata,
  startTabata,
  setTabata,
  isStarted,
  stopTabata,
  isStarting,
}) => {
  let workRef = React.createRef();
  let restRef = React.createRef();
  let roundsRef = React.createRef();
  let countdownRef = React.createRef();

  const [values, setValues] = useState({
    work: tabata.workString,
    rest: tabata.restString,
    rounds: tabata.rounds,
    countdown: tabata.countdownString,
  });

  const [error, setError] = useState(false);
  const handle = (e) => {
    e.preventDefault();

    let tabataInput;

    tabataInput = {
      work: workRef.current.value > 0 ? workRef.current.value : tabata.work,
      rest: restRef.current.value > 0 ? restRef.current.value : tabata.rest,
      countdown:
        countdownRef.current.value > 0
          ? countdownRef.current.value
          : tabata.countdown,
      rounds:
        roundsRef.current.value > 0 ? roundsRef.current.value : tabata.rounds,
      workString: converTime(
        workRef.current.value > 0 ? workRef.current.value : tabata.work
      ),
      restString: converTime(
        restRef.current.value > 0 ? restRef.current.value : tabata.rest
      ),
      countdownString: converTime(
        countdownRef.current.value > 0
          ? countdownRef.current.value
          : tabata.countdown
      ),
    };

    setTabata(tabataInput);
  };

  const checkInput = (e) => {
    if (
      !isNaN(e.target.value) &&
      parseInt(Number(e.target.value)) == e.target.value &&
      !isNaN(parseInt(e.target.value, 10))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setValue = (e) => {
    if (checkInput(e)) {
      if (e.target.id == "work") {
        setValues({
          ...values,
          work: converTime(e.target.value),
        });
      }
    }
  };

  const checkLenght = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }

    if (e.target.value > 1) {
      setError(false);
    } else {
      setError(true);
    }

    if (e.target.id == "work") {
      setValues({
        ...values,
        work: converTime(e.target.value),
      });
    }

    if (e.target.id == "rest") {
      setValues({
        ...values,
        rest: converTime(e.target.value),
      });
    }

    if (e.target.id == "rounds") {
      setValues({
        ...values,
        rounds: e.target.value,
      });
    }

    if (e.target.id == "countdown") {
      setValues({
        ...values,
        countdown: converTime(e.target.value),
      });
    }
  };
  return (
    <div className="time-form-wrapper uk-flex uk-flex-middle uk-flex-center">
      <div className="uk-text-center">
        <div className="time-form-title">
          <h1>Tabata Timer</h1>
        </div>
        <form onChange={handle}>
          <label
            className="uk-form-label"
            for="work">{`Work Time: ${values.work}`}</label>

          <input
            ref={workRef}
            disabled={isStarted || isStarting ? "disable" : ""}
            id="work"
            type="number"
            onChange={checkLenght}
            maxLength={4}
            min={5}
            max={3599}
            className="uk-input time-form-item"
            placeholder="Work (in secs)"></input>

          <label
            className="uk-form-label"
            for="rest">{`Rest Time: ${values.rest}`}</label>

          <input
            ref={restRef}
            disabled={isStarted || isStarting ? "disable" : ""}
            id="rest"
            type="number"
            onChange={checkLenght}
            maxLength={4}
            min={5}
            max={3599}
            className="uk-input time-form-item"
            placeholder="Rest (in secs)"></input>

          <label
            className="uk-form-label"
            for="rounds">{`Rounds: ${values.rounds}`}</label>

          <input
            ref={roundsRef}
            disabled={isStarted || isStarting ? "disable" : ""}
            id="rounds"
            type="number"
            onChange={checkLenght}
            maxLength={2}
            min={2}
            max={60}
            className="uk-input time-form-item"
            placeholder="Rounds"></input>

          <label
            className="uk-form-label"
            for="countdown">{`Countdown Time: ${values.countdown}`}</label>

          <input
            ref={countdownRef}
            disabled={isStarted || isStarting ? "disable" : ""}
            id="countdown"
            type="number"
            onChange={checkLenght}
            maxLength={2}
            min={2}
            max={60}
            className="uk-input time-form-item"
            placeholder="Countdown Time"></input>
        </form>
        <div className="uk-flex uk-flex-middle uk-flex-center time-form-item">
          {isStarted || isStarting ? (
            <button
              onClick={stopTabata}
              className="uk-button uk-button-primary  button-primary">
              Stop
            </button>
          ) : (
            <button
              onClick={startTabata}
              disabled={error}
              className="uk-button  uk-button-primary button-primary">
              Start
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export { TimerForm };
