import React from "react";
import navbar from "../../data/Navbar.json";
import { Link } from "react-router-dom";
const BottonNavbar = () => {
  return (
    <React.Fragment>
      <div uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky">
        <nav
          class="uk-navbar-container bottom-navbar"
          id="bottom-navbar"
          uk-navbar="true">
          <div
            class="uk-navbar-left uk-width-expand"
            style={{ padding: "0 20px 0 20px" }}>
            <Link to="/" className="uk-navbar-item uk-logo logo logo-navbar">
              k-Rel Cornejo
            </Link>

            <ul
              class="uk-navbar-nav uk-flex uk-flex-between uk-width-expand bottom-navbar-nav  uk-visible@m"
              style={{ padding: "0 150px 0 150px" }}>
              {navbar.map((navitem) => (
                <li>
                  <Link to={navitem.link ? navitem.link : "#"}>
                    {navitem.title}
                  </Link>

                  {navitem.subtitles && (
                    <div class="uk-navbar-dropdown">
                      <ul class="uk-nav uk-navbar-dropdown-nav">
                        {navitem.subtitles.map((sub) => (
                          <li>
                            <Link to={sub.link}>{sub.sutbtitleName}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="uk-navbar-right">
            <button
              className="uk-navbar-toggle uk-hidden@m"
              uk-navbar-toggle-icon="true"
              type="button"
              uk-toggle="target: #offcanvas-nav-primary">
              <svg
                fill="black"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
                data-svg="navbar-toggle-icon">
                <rect y="9" width="20" height="2"></rect>
                <rect y="3" width="20" height="2"></rect>
                <rect y="15" width="20" height="2"></rect>
              </svg>
            </button>
          </div>
        </nav>
      </div>
      <div id="offcanvas-nav-primary" uk-offcanvas="overlay: true">
        <div class="uk-offcanvas-bar uk-flex uk-flex-column">
          <Link
            to="/"
            className="logo logo-navbar"
            style={{ textAlign: "center", fontSize: "30px" }}>
            K-rel Cornejo
          </Link>

          <ul
            class="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical uk-nav-parent-icon"
            uk-nav="true">
            {navbar.map((navitem) => (
              <li className={`${!navitem.link && "uk-parent"}`}>
                <Link to={navitem.link ? navitem.link : "#"}>{navitem.title}</Link>

                {navitem.subtitles && (
                  <ul class="uk-nav-sub">
                    {navitem.subtitles.map((sub) => (
                      <li>
                        <Link to={sub.link}>{sub.sutbtitleName}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
          <div
            className="uk-flex uk-flex-between"
            style={{ marginTop: "auto", listStyleType: "none" }}>
            <li>
              <a href="#" uk-icon="icon: facebook; ratio: 1.5"></a>
            </li>
            <li>
              <a href="#" uk-icon="icon: whatsapp; ratio: 1.5"></a>
            </li>
            <li>
              <a href="#" uk-icon="icon: instagram; ratio: 1.5"></a>
            </li>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BottonNavbar;
