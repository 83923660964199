import React from 'react';

const DMediterranea = ({clickGoBack}) => {
    return (
        <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/dieta-mediterranea-bg.jfif" }}>
        <h1 className="title" onClick={clickGoBack}>
          Dieta Mediterranea
        </h1>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        
      </div>
    </div>
    );
};

export default DMediterranea;