import React, { useEffect, useState } from "react";
import CHForm from "./Ch/CHForm";
import { convertLBtoKG } from "../services/helper";
import "../css/hidratacion.css";
const CalculadoraHidratacion = () => {
  const [chObject, setChObject] = useState({
    weight: 0,
    metric: false,
    imperial: false,
  });
  const [ch, setCH] = useState(0);

  useEffect(() => {
    let CH;
    if (chObject.metric) {
      CH = (chObject.weight * 35) / 1000;
    } else {
      CH = (convertLBtoKG(chObject.weight) * 35) / 1000;
    }

    setCH(CH);
  }, [chObject]);

  return (
    <div className="uk-child-width-expand@s " uk-grid="true">
      <div
        className="uk-flex h-result uk-flex-center uk-flex-middle uk-flex-column"
        style={{ backgroundImage: "url(/img/water.jpg)" }}
      >
        <img className="waterdrop" src="/img/waterdrops.svg" />
        {ch > 0 ? (
          <h1 className="title h-intro-result" style={{ fontSize: "40px" }}>
            Tu consumo de agua diaro es de {ch} L
          </h1>
        ) : (
          <h1 className="title h-intro" style={{ fontSize: "40px" }}>
            Calcula la cantitad de hidratacion que necesitas al dia
          </h1>
        )}
      </div>
      <CHForm setCH={setChObject} />
    </div>
  );
};

export default CalculadoraHidratacion;
