import React, { useEffect } from "react";

import { useBMI } from "../hooks/useBMI";

import { BMIForm, BMIResult, BMIIntro } from "./BMI/index";
import PlatformButton from "./PlatformButton";
import Header from "./Header";

import "../css/BMI.css";

const BMI = () => {
  const { bmi, setBMI } = useBMI();

  return (
    <>
      <Header link={"/img/homepage-header.jpg"}>
        <div className="header-body uk-text-center">
          <h1>IMC</h1>
        </div>
      </Header>
      <div className="bmi-wrapper uk-flex uk-flex-middle uk-flex-center">
        <div>
          <BMIIntro />
          <div
            className="bmi-content uk-width-expand uk-child-width-expand@s"
            uk-grid="true"
          >
            <BMIResult bmi={bmi.bmi} />
            <BMIForm calculated={bmi.calculated} setBMI={setBMI} />
          </div>
        </div>
      </div>
      <div className="option-text uk-text-center uk-flex uk-flex-center uk-flex-middle uk-flex-column">
        <h1 className="title">
          PARA MEJORAR TU SALUD Y COMPOSICION CORPORAL CONTACTA CONMIGO
        </h1>
        <PlatformButton />
      </div>
    </>
  );
};

export default BMI;
