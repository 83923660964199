import { useState, useEffect } from "react";
import {
  caloriesImperial,
  caloriesMetric,
  calculateMacrosMetric,
  calculateMacrosImperial,
} from "../services/helper";

const defaultCC = {
  metric: false,
  imperial: false,
  calculated: false,
  weight: 0,
  height: 0,
  gender: "",
  age: 0,
  activity: 1,
  calories: 0,
  objective: 0,
  proteins: 0,
  fat: 0,
  hc: 0,
};

const useCC = (initialCC = defaultCC) => {
  const [CC, setCC] = useState(initialCC);

  useEffect(() => {
    const { weight, height, calculated, objective } = CC;
    if (weight > 0 && height > 0 && !calculated) {
      if (CC.metric) {
        const macros = calculateMacrosMetric(objective, weight);
        setCC({
          ...CC,
          calories: caloriesMetric(CC),
          calculated: true,
          proteins: macros.protein,
          fat: macros.fat,
          hc: macros.c,
        });
      }

      if (CC.imperial) {
        const macros = calculateMacrosImperial(objective, weight);
        setCC({
          ...CC,
          calories: caloriesImperial(CC),
          calculated: true,
          proteins: macros.protein,
          fat: macros.fat,
          hc: macros.c,
        });
      }
    }
  });
  const setCalories = (values) => {
    setCC({
      ...CC,
      ...values,
      calculated: false,
    });
  };
  return {
    CC,
    setCalories,
  };
};

export default useCC;
