import React from "react";
import "../../css/dietas/DietaDeZona.css";
import PlatformButton from "../PlatformButton";

const DZona = ({ clickGoBack }) => {
  return (
    <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/dieta-paleo.jpg" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Dieta de Zona
        </h1>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        <div
          className="zona-intro"
          style={{ backgroundImage: "url('/img/dzona-intro-bg.jpg')" }}
        >
          <h1 className="title">
            Si la salud es la ausencia de enfermedad, una alimentacion
            inteligente te permite estar en una zona vital para tu
            productividad, pero ¿Que es la zona?
          </h1>
        </div>

        <div
          className=" zona-text uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle "
          uk-grid="true"
        >
          <div style={{ backgroundImage: "url(/img/dzona-1-bg.jpg)" }}>
            <h1 className="title">Equilibrio</h1>
            <p>
              La dieta de la zona es el equilibrio de las respuestas hormonales
              que se producen cada vez que comemos. Un equilibrio perfecto: ni
              demasiado alto ni demasiado bajo que tiene como beneficios
            </p>
          </div>

          <div style={{ backgroundImage: "url(/img/dzona-2-bg.jpg)" }}>
            <h1 className="title">Alimentos</h1>
            <p>
              Comer en la zona, es tratar los alimentos con el mismo respeto con
              que se trata una medicina prescrita por un médico, para llevar un
              control hormonal durante toda la vida, olvidándote por completo
              contar calorías o gramos de grasa.
            </p>
          </div>

          <div style={{ backgroundImage: "url(/img/dzona-3-bg.jpg)" }}>
            <h1 className="title">Insulina</h1>
            <p>
              La grasa no es el enemigo, si no lo es, ¿Entonces, quien? La
              insulina. El exceso de insulina es lo que te hace engordar y te
              mantiene en este estado. El cuerpo la produce en cantidades
              excesivas cuando en una comida se comen demasiados hidratos de
              carbono bajos en grasa o demasiadas calorías.
            </p>
          </div>

          <div style={{ backgroundImage: "url(/img/dzona-4-bg.jpg)" }}>
            <h1 className="title">Proteína</h1>
            <p>
              Para llegar a la Zona y aprovechar sus beneficios depende
              básicamente del control de la insulina, un paso importante para
              realizar el control consiste en dar al cuerpo la cantidad de
              proteina que necesita.
            </p>
          </div>
        </div>
        <div
          className="dzona-list"
          style={{ backgroundImage: "url(/img/dzona-list-bg.jpg" }}
        >
          <h1 className="title">Beneficios</h1>
          <ul className="uk-child-width-expand@s " uk-grid="true">
            <li>
              <img src="/img/dzona-list-1.svg" />
              <p>
                Pensar mejor, porque en La Zona los niveles de azúcar se
                mantienen estables.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-2.svg" />
              <p>
                Mejor rendimiento, La Zona te permite aumentar la afluencia de
                oxígeno a las células del tejido muscular.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-3.svg" />
              <p>
                Mejorar tu composición corporal, porque eliminas el exceso de
                grasa corporal de forma rápida.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-4.svg" />
              <p>
                No tener que pasar hambre entre comidas, ya que una alimentación
                inteligente que te haga estar en La Zona, hace que tu cerebro
                reciba un aporte constante de su principal combustible: el
                azúcar en la sangre.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div
        className="dzona-platform uk-flex uk-flex-center uk-flex-middle"
        style={{ backgroundImage: "url(/img/dzona-platform-bg.jpg)" }}
      >
        <h1 className="title">
          Para cualquier cambio importante en tu dieta y régimen de
          entrenamiento te recomendamos nuestro plan. Consulta nuestra pagina
          que te ayudará a que logres tus objetivos, mejorando tu composicion
          corporal con una alimentacion inteligente.
        </h1>
        <PlatformButton />
      </div>
    </div>
  );
};

export default DZona;
