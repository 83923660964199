import React, { useEffect } from "react";
import Header from "./Header";
import SocialMediaCounter from "./homepage/SocialMediaCounter";
import ShortAboutMe from "./homepage/ShortAboutMe";
import HelpCards from "./homepage/HelpCards";
import { Link } from "react-router-dom";

const Homepage = () => {
  useEffect(() => {
    var allElements = document.querySelectorAll("*");
    var bigElements =
      // use the filter function to filter get only elements you need
      Array.prototype.filter.call(
        allElements,
        // compares occupied width with window width
        (element) => element.getBoundingClientRect().width > window.innerWidth
      );

    // print resulting elements
    bigElements.forEach((element) => console.log(element));
    if (!bigElements.length) console.log("Yaay! All elements fit. ");
  }, []);
  return (
    <div>
      <Header link={"/img/homepage-header.jpg"}>
        <div className="header-body uk-text-center bg-body homepage-header">
          <h1>Haz lo que hacen todos y conseguirás lo que todos consiguen</h1>
          <h2 className="logo">K-rel Cornejo</h2>

          <Link
            to={"/FitAndMindset"}
            class="uk-button uk-button-primary button-primary"
          >
            Fit N' Mindset
          </Link>
        </div>
      </Header>

      <HelpCards />

      <ShortAboutMe />

      <SocialMediaCounter />
    </div>
  );
};

export default Homepage;
