import React from "react";

const PlatformButton = () => {
  return (
    <a
      target="_target"
      href="https://wa.me/34688392497"
      className="uk-button uk-button-primary button-primary platform-button"
      style={{ margin: "30px" }}
    >
      <img src="/img/weight-logo.svg" />
      <p>Escribeme</p>
    </a>
  );
};

export default PlatformButton;
