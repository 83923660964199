import React, { useState } from "react";
import useFormSlider from "../hooks/useFormSlider/useFormSlider";
import {
  Musculacion,
  RendimientoDeportivo,
  PerdidaDeGrasa,
  HabitosSaludables,
} from "./composición-corporal/index";

import "../css/MejorarComposicionCorporal.css";
const MejorarComposicionCorporal = () => {
  const { FormSlider, clickGoNext, clickGoBack } = useFormSlider(2);

  const MUSCULACION = 1;
  const GRASA = 2;
  const DEPORTE = 3;
  const HSALUDABLES = 4;

  const [option, setOption] = useState(0);

  const setSelection = (select) => {
    setOption(select);
    clickGoNext();
  };
  return (
    <div className="option-wrapper">
      <FormSlider>
        <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
          <h1 className="title">Seleccionar tu sexo </h1>
          <div
            className="uk-child-width-expand@s uk-flex uk-flex-center"
            uk-grid="true"
          >
            <div className="single-option-wrapper">
              <div
                onClick={clickGoNext}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-woman.jpg)" }}
              >
                <h1 className="title">Mujer</h1>
              </div>
            </div>
            <div className="single-option-wrapper">
              <div
                onClick={clickGoNext}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-men.jpg)" }}
              >
                <h1 className="title">Hombre</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="option-content uk-flex-center uk-flex-middle uk-flex uk-overflow-hidden">
          <h1 className="title">
            <img src="/img/previous.svg" onClick={clickGoBack} /> ¿Cual es tu
            objetivo?
          </h1>
          <div
            className="uk-child-width-1-1@s uk-child-width-1-2@m "
            uk-grid="true"
          >
            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(MUSCULACION)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/musculacion-bg.jpg" }}
              >
                <h1 className="title">Musculación</h1>
              </div>
            </div>
            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(GRASA)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/weight-loss-bg.jpg)" }}
              >
                <h1 className="title">Perdida de grasa</h1>
              </div>
            </div>
            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(DEPORTE)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/sport-bg.jpg)" }}
              >
                <h1 className="title">Rendimiento Deportivo</h1>
              </div>
            </div>
            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(HSALUDABLES)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/hs-bg.jpg)" }}
              >
                <h1 className="title">Habitos Saludables</h1>
              </div>
            </div>
          </div>
        </div>

        {option === MUSCULACION && <Musculacion clickGoBack={clickGoBack} />}

        {option === GRASA && <PerdidaDeGrasa clickGoBack={clickGoBack} />}

        {option === DEPORTE && (
          <RendimientoDeportivo clickGoBack={clickGoBack} />
        )}
        {option === HSALUDABLES && (
          <HabitosSaludables clickGoBack={clickGoBack} />
        )}
      </FormSlider>
    </div>
  );
};

export default MejorarComposicionCorporal;
