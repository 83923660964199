import "./css/App.css";
import { useEffect } from "react";
import Routing from "./components/Routing";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return <Routing />;
}

export default App;
