import React, { useState, useEffect } from "react";
import "../css/TabataTimer.css";
import { useTabata } from "../hooks/useTabata";

import { Timer, TimerForm } from "./Tabata/index";

const TabataTimer = () => {
  const InitalTabata = {
    rounds: 10,
    work: 20,
    workString: "00:20",
    rest: 10,
    restString: "00:10",
    countdown: 10,
    countdownString: "00:10",
    isStarting: false,
    isStarted: false,
    isFinished: false,
  };

  let workTimer;

  const { tabata, startTabata, stopTabata, handleTabataChange } = useTabata(
    InitalTabata
  );

  const setTabata = (timer) => {
    console.log(timer);
    handleTabataChange(timer);
  };

  return (
    <main className="tabata-timer-wrapper uk-flex uk-flex-center uk-flex-middle uk-text-center">
      <div
        className="tabata-timer uk-width-expand uk-child-width-expand@s"
        uk-grid="true">
        <Timer tabata={tabata} />
        <TimerForm
          tabata={tabata}
          startTabata={startTabata}
          setTabata={setTabata}
          stopTabata={stopTabata}
          isStarted={tabata.isStarted}
          isStarting={tabata.isStarting}
        />
      </div>
    </main>
  );
};

export default TabataTimer;
