import React, { createRef, useState } from "react";

import Switch from "../Switch";

const CCFormulario = ({ setCC, calculated }) => {
  const [metric, setMetric] = useState(true);
  const [rangeValue, setRangeValue] = useState("");

  const [activity, setActivity] = useState(1);
  const [objective, setObjective] = useState(0);
  const weightRef = createRef();
  const heigttRef = createRef();
  const GenderRef = createRef();
  const AgeRef = createRef();
  const ActivityRef = createRef();

  const ObjectiveRef = createRef();
  const handle = (e) => {
    e.preventDefault();

    let CCvalues;

    if (metric) {
      CCvalues = {
        weight: weightRef.current.value,
        height: heigttRef.current.value,
        gender: GenderRef.current.value,
        age: AgeRef.current.value,
        activity: activity,
        objective: objective,
        metric: true,
        imperial: false,
      };
    } else {
      CCvalues = {
        weight: weightRef.current.value,
        height: heigttRef.current.value,
        gender: GenderRef.current.value,
        age: AgeRef.current.value,
        activity: activity,
        objective: objective,
        imperial: true,
        metric: false,
      };
    }

    setCC(CCvalues);
    console.log(CCvalues);
  };

  const changeForm = (e) => {
    if (calculated) {
      setCC({
        calculated: !calculated,
      });
    }
  };
  return (
    <div className="CC-form-wrapper">
      <div className="CC-form-title uk-text-center">
        <h1>Calculadora de Calorias</h1>
      </div>

      <div className="uk-margin">
        <Switch
          labelText={metric ? "Metric" : "Imperial"}
          onChange={() => setMetric(!metric)}
          size={30}
          color={"var(--principal-color)"}
        />
      </div>

      <form
        onSubmit={handle}
        /* onChange={changeForm} */ className="uk-text-center CC-form-form"
      >
        <div className="gender-radio-container uk-flex uk-flex-center uk-flex-middle">
          <div className="gender-radio">
            <div className="gender-image">
              <img src="/img/female-gender.svg" />
            </div>
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="gender"
                id="female"
                className="uk-radio"
                value="f"
                ref={GenderRef}
              />
              Mujer
            </label>
          </div>

          <div className="gender-radio">
            <div className="gender-image">
              <img src="/img/male-gender.svg" />
            </div>
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="gender"
                id="female"
                className="uk-radio"
                value="m"
                ref={GenderRef}
              />
              Hombre
            </label>
          </div>
        </div>
        <div class="uk-margin range">
          <h3>Edad: {rangeValue}</h3>
          <input
            class="uk-range"
            type="range"
            onChange={(e) => setRangeValue(e.target.value)}
            value={rangeValue}
            min="6"
            max="100"
            step="1"
            ref={AgeRef}
          />
        </div>
        <div className="uk-margin">
          <input
            className="uk-input"
            ref={weightRef}
            placeholder={metric ? "Peso (kg)" : "Peso (Lb)"}
          />
        </div>
        <div className="uk-margin">
          <input
            className="uk-input"
            ref={heigttRef}
            placeholder={metric ? "Altura (cm)" : "Altura (pulg)"}
          />
        </div>

        <div>
          <h1>Actividad fisica</h1>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="activity"
                className="uk-radio"
                value={1.2}
                onChange={() => setActivity(1.2)}
                ref={ActivityRef}
              />
              Poco o ningún ejercicio
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="activity"
                className="uk-radio"
                value={1.375}
                onChange={() => setActivity(1.375)}
                ref={ActivityRef}
              />
              Ejercicio ligero (1 a 3 días a la semana)
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="activity"
                className="uk-radio"
                value={1.55}
                onChange={() => setActivity(1.55)}
                ref={ActivityRef}
              />
              Ejercicio moderado (3 a 5 días a la semana)
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="activity"
                className="uk-radio"
                value={1.72}
                onChange={(e) => setActivity(1.72)}
                ref={ActivityRef}
              />
              Deportista (6 -7 días a la semana)
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="activity"
                className="uk-radio"
                value={1.9}
                onChange={(e) => setActivity(1.9)}
                ref={ActivityRef}
              />
              Atleta (Entrenamientos mañana y tarde)
            </label>
          </div>
        </div>

        <div>
          <h1>Objetivo</h1>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="objective"
                className="uk-radio"
                value={0}
                onChange={() => setObjective(0)}
                ref={ObjectiveRef}
              />
              Perdida de grasa
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="objective"
                className="uk-radio"
                value={1}
                onChange={() => setObjective(1)}
                ref={ObjectiveRef}
              />
              Aumento de masa muscular
            </label>
          </div>
          <div className="uk-margin uk-flex uk-flex-left">
            <label className="time-form-item label-radio">
              <input
                type="radio"
                name="objective"
                className="uk-radio"
                value={2}
                onChange={() => setObjective(2)}
                ref={ObjectiveRef}
              />
              Alimentacion saludable
            </label>
          </div>
        </div>
        <div className="uk-margin ">
          <button
            className="uk-button  uk-button-primary button-primary"
            type="submit"
          >
            Calcular
          </button>
        </div>
      </form>
    </div>
  );
};

export default CCFormulario;
