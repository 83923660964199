import React, { useEffect } from "react";
import Header from "./Header";
import "../css/FitAndMindset.css";
import { Link } from "react-router-dom";
import AOS from "aos";
const FitAndMindset = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  return (
    <React.Fragment>
      <Header link={"/img/homepage-header.jpg"}>
        <div className="header-body uk-text-center">
          <h1>Fitness & Mindset</h1>
        </div>
      </Header>
      <main className="fitandmindset" style={{ overflowX: "hidden" }}>
        <div
          className="uk-text-center fitandmindset-intro-wrapper"
          style={{ backgroundImage: "url(/img/fitandmindset-intro-bg.jpg)" }}
        >
          <div
            className="uk-flex uk-flex-middle uk-flex-left"
            data-aos="fade-right"
          >
            <div className="uk-text-left fitandmindset-intro">
              <h1>FIT AND MINDSET COACHING</h1>

              <p>
                Un programa que no solo trata de mejorar tu composición corporal
                con cambios corporales a base de ejercicios físicos y nutrición,
                este programa hace especial hincapié a la preparación mental (El
                cerebro también es un musculo, el cual se tiene que entrenar) en
                base a pequeños hábitos que hacen enormes cambios en nuestra
                fisiología y en nuestro estilo
              </p>
            </div>
          </div>

          <div
            className="uk-flex uk-flex-middle uk-flex-right"
            data-aos="fade-left"
          >
            <div className="uk-text-right fitandmindset-intro">
              <h1>FitAndMindsetProgram™ </h1>

              <p>
                Este programa te brinda el conocimiento necesario mediante la
                practica para que aprendas a llevar una alimentación flexible
                adecuada a tu cuerpo, aparte de tus objetivos puntuales
                buscaremos un físico saludable mediante rutinas de ejercicios
                personalizadas para que puedas mantener y mejorar al largo de
                los años, incrementando tu productividad personal.
              </p>
            </div>
          </div>
        </div>

        <div
          className="uk-flex uk-flex-middle uk-flex-center "
          data-aos="zoom-in-right"
        >
          <div className="fitandmindset-benefits">
            <h1>BENEFICIOS</h1>

            <ul
              className="uk-child-width-1-1@s uk-child-width-1-2@m uk-flex uk-flex-top uk-grid-collapse "
              uk-grid="true"
            >
              <li data-aos="zoom-in">
                <h2>
                  Obtienes una mejora de tu composición corporal con
                  entrenamientos respaldados por la ciencia sin hacer
                  repeticiones de mas.
                </h2>
              </li>

              <li data-aos="zoom-in">
                <h2>
                  Entrenamiento rápido y efectivo, basado en la optimización del
                  tiempo estimulando los principales grupos musculares para
                  mejores resultados.
                </h2>
              </li>

              <li data-aos="zoom-in">
                <h2>
                  Tienes acceso a una plataforma exclusiva, donde tendrás
                  entrenamientos personalizados y videos explicativos para
                  realizar la técnica correcta de los ejercicios sin temor a
                  lesionarte.
                </h2>
              </li>

              <li data-aos="zoom-in">
                <h2>
                  Con alimentación inteligente aceleraras el metabolismo para
                  favorecer la quema de grasa incluso durante horas después del
                  entrenamiento, estimularas la producción de la hormona del
                  crecimiento, la cual reduce la grasa y ayuda en la definición
                  muscular.
                </h2>
              </li>
            </ul>
          </div>
        </div>

        <div
          className="fitandmindset-topics uk-child-width-expand@s uk-grid-collapse"
          uk-grid="true"
          style={{ overflow: "hidden" }}
        >
          <div className="fitandmindset-topics-eat " data-aos="fade-right">
            <div className="uk-flex uk-flex-center uk-flex-middle uk-text-center">
              <h1 className="title">Alimentación Inteligente</h1>
            </div>
            <div className="uk-text-center">
              <div
                className="fitandmindset-topics-eat-content"
                data-aos="zoom-in"
              >
                <h3>
                  ¿Sabes alimentarte correctamente? <br /> Una alimentación
                  inteligente es mas sencillo de lo crees!
                </h3>

                <ul className="uk-list uk-list-none">
                  <li>
                    <p>
                      No es enfocarse en dietas restrictivas con efecto rebote
                      que funcionan durante una temporada y después parece que
                      todo (hasta respirar) te engorda porque te centras
                      exclusivamente en tu físico.
                    </p>
                  </li>

                  <li>
                    <p>
                      No es priorizar un conteo estricto de calorías imposible
                      de mantener durante todo el año ya que esto no deja ver
                      los resultados que esperamos en base a objetivos a corto
                      plazo.
                    </p>
                  </li>

                  <li>
                    <p>
                      No es copiar recetas de otras personas y pensar que a
                      nosotros nos darán los mismos resultados.
                    </p>
                  </li>
                </ul>
              </div>

              <h2>
                "La comida que comes puede ser la más poderosa forma de medicina
                o la forma más lenta de veneno".-Ann Wigmore.
              </h2>
            </div>
          </div>

          <div className="fitandmindset-topics-comp  " data-aos="fade-left">
            <div className="uk-text-center uk-flex uk-flex-center uk-flex-middle">
              <h1 className="title">
                Mejorar mi <br /> composición corporal{" "}
              </h1>
            </div>
            <div
              className="uk-text-center uk-flex uk-flex-center uk-flex-middle"
              data-aos="zoom-in"
            >
              <div>
                <p>
                  Has probado de todo y sigues sin conseguir el cuerpo que
                  quieres, has probado todas las dietas que existen y has visto
                  1´000 recetas ´´saludables´´ que no se comería ni el que las
                  invento y al final vuelves a comer mal. Te ha faltado el aire
                  por usar fajas reductoras, tomando batidos mágicos détox, y
                  alimentos o productos que no valen para nada. Todo esto son
                  más que mitos y otras creencias que han hecho la industria
                  alimentaria.
                </p>

                <p>
                  Cada cuerpo es diferente, partimos de bases diferentes,
                  diferentes metabolismos, mentalidad, genética, objetivos...
                  Por eso considero importante una personalización y seguimiento
                  con un profesional.
                </p>

                <a href="/books/E-BOOK MEJORA TU COMPOSICION CORPORAL BY K-REL CORNEJO.pdf">
                  <h2 className="title free-ebook">
                    Obten tu ebook gratuito aqui
                  </h2>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="fitandmindset-platform uk-flex uk-flex-middle uk-flex-center uk-width-expand"
          data-aos="zoom-in"
          style={{
            backgroundImage: "url(/img/fit-n-mindet-platform-bg.jpg)",
          }}
        >
          <div className="uk-text-center">
            <h1>¿CÓMO EMPEZAR CON EL PROGRAMA?</h1>
            <p>
              Puedes mandar tus datos en la <br /> pagina de contacto o entrar
              directamente en el siguiente enlace:
            </p>
            <a
              target="_blank"
              href="https://app.dudyfit.es/fitandmindset/landing"
              className="uk-button uk-button-primary button-primary"
            >
              Plataforma
            </a>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default FitAndMindset;
