import {useEffect,useState} from 'react';

import {BMIimperial,BMImetric} from '../services/helper';

const defaultBMI={
    metric:false,
    imperial:false,
    calculated:false,
    weight:0,
    height:0,
    bmi:0
}

const useBMI = (initialBMI=defaultBMI) => {
    const [bmi, setBmi]=useState(initialBMI);


    useEffect(()=>{
        const{weight,height,calculated}=bmi;
        if(weight>0 && height>0 && !calculated){
            if(bmi.metric){
                setBmi({
                    ...bmi,
                    bmi:BMImetric(bmi),
                    calculated:true
                })
            }
            
            if(bmi.imperial){
    
                setBmi({
                    ...bmi,
                    bmi:BMIimperial(bmi),
                    calculated:true
                })
                
            }
        }
    })

    const setBMI=(values)=>{
        setBmi({
            ...bmi,
            ...values,
            calculated:false
        })
    }
    return {
        bmi,
        setBMI,
    };
};

export {useBMI};