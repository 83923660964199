import React from "react";

import "../../css/dietas/AIntermitente.css";

import PlatformButton from "../PlatformButton";

const AIntermitente = ({ clickGoBack }) => {
  return (
    <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/ayuno-intermitente-bg.jfif" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Ayuno intermitente
        </h1>
      </div>
      <div className=" option-text uk-flex uk-flex-center uk-flex-middle">
        <div
          className="aintermitente-intro"
          style={{ backgroundImage: "url(/img/aintermitente-intro-bg.jpg)" }}
        >
          <h2 className="title">¿Que es el ayuno intermitente?</h2>
          <p>
            El ayuno intermitente es un metodo que nos indica cómo consumir las
            calorías a lo largo del día, principalmente nos limita el tiempo en
            que empezamos y terminamos de comer. es básicamente un patrón
            alimenticio que depende de ciertos horarios.
          </p>
          <p>
            El ayuno intermitente no es una técnica quemadora de grasa, no
            pienses esto, ni te dejes engañar con lo que dicen muchas personas,
            este ayuno no adelgaza, más bien tiene dos aspectos importantes:
          </p>
          <ul className="uk-child-width-expand@s" uk-grid="true">
            <li>
              <img src="/img/clock.svg" />
              <p>
                A corto plazo es el gasto calórico que uno tiene al final del
                día
              </p>
            </li>
            <li>
              <img src="/img/calendar.svg" />
              <p>
                A largo plazo es que nos vamos a volver más eficientes al
                momento de oxidar grasas porque las vamos a usar como energía.{" "}
              </p>
            </li>
          </ul>
        </div>
        <div
          className="aintermitente-content"
          style={{ backgroundImage: "url(/img/aintermitente-content-1.jpg)" }}
        >
          <h1 className="title">¿CÓMO FUNCIONA EL AYUNO INTERMITENTE?</h1>
          <p>
            El método consiste, en cambiar nuestro horario de comidas de forma
            que combinemos periodos de ayuno y de comida a intervalos. Los
            tiempos podemos ir eligiéndolos nosotros mismos.
          </p>
          <p>
            En el ayuno intermitente hay tiempos muy marcados en los que podemos
            comer y en los que no. Los métodos de ayuno son tan diferentes como
            nuestras necesidades. Escucha a tu propio cuerpo y encuentra los
            tiempos que mejor se adaptan al ritmo de tu organismo.{" "}
          </p>
        </div>
        <div
          className="aintermitente-tipos"
          style={{ backgroundImage: "url(/img/ayuno-intermitente-tipos.jpg)" }}
        >
          <h1 className="title">TIPOS DE AYUNO</h1>
          <ul className="uk-child-width-expand@s " uk-grid="true">
            <li>
              <h3>Ayuno intermitente 16/8 LEANGAINS</h3>
              <p>
                El protocolo de leangains propuesto por Martin Berkhan; nos dice
                que debemos ayunar durante 16 horas y consumir todas las
                calorías que necesitamos a lo largo del día, durante 8 horas,
                prácticamente la mitad del ayuno ya está hecho ya que nosotros
                nos levantamos de un periodo de sueño de 6 a 8 horas, entonces
                lo que hacemos no es levantarnos directamente a desayunar, sino
                prolongarlo hasta que hayamos cumplido las 16 horas y
                posteriormente romper el ayuno.
              </p>
            </li>
            <li>
              <h3>EAT – STOP - EAT</h3>
              <p>
                Este método básicamente, consiste en ayunar 1 o 2 días a la
                semana, comiendo de manera normal el resto de días que no te
                toque ayunar. Asegúrate al seguir este método de que mantienes
                intacta tu ingesta de agua en todo momento.
              </p>
            </li>
            <li>
              <h3>Ayuno Intermitente 18-6</h3>
            </li>
            <li>
              <h3>Ramadán</h3>
              <p>
                Categorizado como un periodo corte de régimen de ayuno
                intermitente (ayuno diurno durante 29 dias).
              </p>
            </li>
          </ul>
        </div>

        <div
          className="aintermitente-beneficios"
          style={{
            backgroundImage: "url(/img/ayuno-intermitente-benefits.jpg)",
          }}
        >
          <h2 className="title">BENEFICIOS DEL AYUNO INTERMITENTE</h2>
          <ul
            className="uk-child-width-1-3@m uk-child-width-1-1@s  "
            uk-grid="true"
          >
            <li>
              <img src="/img/dzona-list-1.svg" />
              <p>Promueve la autofagia </p>
            </li>
            <li>
              {" "}
              <img src="/img/dzona-list-2.svg" />
              <p>Mejora la sensación de apetito.</p>
            </li>
            <li>
              <img src="/img/dzona-list-3.svg" />
              <p>Puede mejorar la salud cerebral.</p>
            </li>
            <li>
              <img src="/img/dzona-list-4.svg" />
              <p>Mejora la sensibilidad a la insulina.</p>
            </li>
            <li>
              <img src="/img/dzona-list-5.svg" />
              <p>Puede promover mayor oxidación de grasas.</p>
            </li>
            <li>
              <img src="/img/dzona-list-6.svg" />
              <p>Aumenta la producción de Hormona del Crecimiento Humano.</p>
            </li>
            <li>
              <img src="/img/dzona-list-7.svg" />
              <p>
                Disminuye el riesgo de enfermedades cardiovasculares, diabetes,
                enfermedades crónicas, presión sanguínea, triglicéridos.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-8.svg" />
              <p>
                Bienestar medible de manera cuantitativa en resultados de
                perfiles lipídicos y glucosa en sangre.
              </p>
            </li>
            <li>
              <img src="/img/dzona-list-9.svg" />
              <p>
                Baja los niveles de colesterol, a largo plazo es beneficioso en
                cuestiones de próstata en los hombres y tejido mamario en las
                mujeres
              </p>
            </li>
          </ul>
          <p>
            Una recomendación para cumplir con el ayuno es hacer coincidir las
            horas de ayuno con nuestras horas nocturnas de sueño. En todo caso,
            deberemos de asegurarnos de beber agua suficiente para estos
            periodos de ayuno.
          </p>
          <p>
            Un buen plan intermitente (16-8) (18-6) es, por tanto, el de
            saltarse la cena por la noche o el desayuno por la mañana.
          </p>
          <p>
            Si quieres perder peso has de tomar menos calorías de las que ea no
            nos hemos de exceder de un déficit calórico de 300 a 500 calorías
            por día.{" "}
          </p>
          <p>
            Para cualquier cambio importante en tu dieta y rutina de
            entrenamiento te recomendamos nuestro plan. Consulta nuestra pagina
            que te ayudará a que logres tus objetivos, mejorando tu composicion
            corporal con una alimentacion inteligente.
          </p>

          <div className=" aintermitente-platform uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            <h1 className="title">
              Para cualquier cambio importante en tu dieta y régimen de
              entrenamiento te recomendamos nuestro plan. Consulta nuestra
              pagina que te ayudará a que logres tus objetivos, mejorando tu
              composicion corporal con una alimentacion inteligente.
            </h1>
            <PlatformButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIntermitente;
