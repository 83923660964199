import React from "react";
import { Link } from "react-router-dom";
const TopNavbar = () => {
  return (
    <div
      className="uk-width-expand@s uk-flex text top-navbar uk-flex-middle"
      uk-grid="true"
      style={{ backgroundColor: "black" }}
    >
      <div className="uk-flex uk-flex-middle top-navbar-item ">
        <Link>
          <b>+34 688 392 497</b>
        </Link>
      </div>
      <div className="divider"></div>

      <div className="uk-flex uk-flex-middle top-navbar-item ">
        <Link to="" uk-icon="facebook"></Link>
      </div>
      <div className="uk-flex uk-flex-middle top-navbar-item ">
        <Link to="" uk-icon="instagram"></Link>
      </div>
      <div className="uk-flex uk-flex-middle top-navbar-item ">
        <Link to="" uk-icon="twitter"></Link>
      </div>
    </div>
  );
};

export default TopNavbar;
