import React from 'react';
import nav from '../data/Navbar.json';
import socialmedia from '../data/SocialMedia.json'
import {Link} from 'react-router-dom';
const Footer = () => {
    return (
        <div className="uk-flex uk-flex-middle uk-flex-center" style={{height:"auto",backgroundColor:"black", padding:"40px 0 40px 0"}} uk-grid="true">
            <div className="uk-width-2-5@m uk-width-expand@s uk-text-center footer-title">
                <Link to="/" style={{color:"white"}}>k-rel Cornejo</Link>
                <p>
                Apasionado por la buena condición física, comparto mi conocimiento para ayudarte e inspirarte a mejorar tu vida. #FitandMindset es mi programa, en el aplico todas mis investigaciones y experiencia para facilitarte el camino.
                Gracias por visitar mi web.
                </p>
            </div>
            
            <div className="uk-width-expand@m uk-width-expand@s uk-text-center uk-flex uk-flex-middle uk-flex-center divider-large">
                <ul class="uk-nav uk-nav-default uk-text-left footer-nav" style={{margin: "0 0 0 30px"}}>
                    <li><Link to="/FitAndMindset">Fit N' Mindset</Link></li>
                    <li><Link to="/SobreMi">Sobre K-rel Cornejo</Link></li>
                    <li><Link to="/FAQS">FAQS</Link></li>
                    <li><Link to="/SportsPsychologyAndCoaching">Sports Psychology Coaching</Link></li>
                    <li><Link to="/MejorarComposicionCorporal">Mejorar composición corporal</Link></li>


                </ul>
                
                <ul class="uk-nav uk-nav-default uk-text-left footer-nav" style={{margin: "0 0 0 30px"}}>
                    
                    <li><Link to="/AlimentacionInteligente">Alimentacion Inteligente</Link></li>
                    <li><Link to="/tabatatimer">Cronómentro Tabata</Link></li>
                    <li><Link to="/RecomendedBooks">Recomendación de libros</Link></li>
                    <li><Link to="/BMI">Calculadora de IMC</Link></li>
                    <li><Link to="/Contact">Contacto</Link></li>
                    
                </ul>
            </div>

            <div className="uk-width-expand@m uk-width-expand@s" >
                <div className="uk-width-expand  uk-flex uk-flex-middle uk-flex-center footer-title-social-media">
                    <h1>Sigueme</h1>

                </div>

                <div className="uk-width-expand uk-text-center uk-flex uk-flex-middle uk-flex-center">
                    <ul class="uk-nav uk-width-2-3 uk-nav-default uk-flex uk-flex-between" >
                                {
                                    Object.keys(socialmedia).map(key=>(
                                        <li  key={key} ><a target="_blank" className="footer-social-media" href={`${socialmedia[key].link}`} uk-icon={`icon: ${socialmedia[key].icon}; ratio: 2.5`}></a></li>
                                    ))
                                }
                                
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Footer;