import React from 'react';

const Header = ({link,children}) => {


    return (
        <div className="uk-whidth-expand">
            <div>
                <div className="uk-background-cover uk-height-medium uk-panel uk-flex uk-flex-center uk-flex-middle bg-image" style={{"--bg": `url(${link})`}}>
                        {children}
                </div>
            </div>
        </div>
    );
};

export default Header;