import React, { useEffect, useState } from "react";
import "../../css/BMI-result.css";
import { useSpring, animated } from "react-spring";
import { BMItext, BMIComentary } from "../../services/helper";
const BMIResult = ({ bmi }) => {
  /* const [spring,setLeft] = useSpring(() =>
    ({
        config: { duration: 2500 },
      left: "0",
      bottom:"0",
    }))


        if(bmi>0){
            console.log(bmi);
            if(bmi==18.5){
                left = { left: 'calc(25% - var(--width))' })
            }
        
            if(bmi>18.5 && bmi<25){
                
                left = { left: `calc(${25+((bmi-18.5)/6.5)}% - var(--width))` })
            }
        
            if(bmi==25){
                left = { left: 'calc(50% - 25px)' })
            }
        
            if(bmi>25 && bmi<30){
                console.log(50+((bmi-25)/5)*25);
                left = { left: `calc(${50+((bmi-25)/5)*25}% - 25px)` })
            }
        
        
            if(bmi==30){
                left = { left: 'calc(75% + var(--width2))' })
            }
        
            if(bmi>30 && bmi<35){
                left = { left: `calc(${75+((bmi-30)/5)*25}% + var(--width2))` })
            }
        
            if(bmi>35){
                left = { left: '94%' })
            }
        }  */

  let left = "0";

  if (bmi > 0) {
    console.log(bmi);
    if (bmi == 18.5) {
      left = "calc(14.30*2% - var(--width))";
    }

    if (bmi > 18.5 && bmi < 25) {
      left = `calc(${
        14.3 * 2.5 + ((bmi - 18.5) / 6.5) * 14.3
      }% - var(--width))`;
    }

    if (bmi == 25) {
      left = `calc(${14.3 * 3.5}% - 25px)`;
    }

    if (bmi > 25 && bmi < 30) {
      console.log(14.3 * 3.5 + ((bmi - 25) / 5) * 25);
      left = `calc(${14.3 * 3.5 + ((bmi - 25) / 5) * 14.3 * 2}% - 25px)`;
    }

    if (bmi == 30) {
      left = `calc(${14.3 * 5.5}% - var(--width2))`;
    }

    if (bmi > 30 && bmi < 35) {
      left = `calc(${
        14.3 * 5.5 + ((bmi - 30) / 5) * 14.3 * 2
      }% - var(--width2))`;
    }

    if (bmi > 35) {
      left = "calc(100% - 38px)";
    }
  }

  return (
    <div className="uk-flex uk-flex-center uk-flex-middle result-wrapper">
      <div class="result-bar-wrapper uk-width-expand uk-width-expand">
        <div className="result-bar-arrow-wrapper">
          <img
            className="result-bar-arrow"
            src="/img/down-arrow.svg"
            style={{ "--left": left }}
          />
        </div>
        <div className="result-bar"></div>
        <div className="result-bar-titles uk-width-expand uk-text-center uk-child-width-expand uk-flex uk-flex-center uk-flex-middle">
          <div className="result-container">
            <h2 className="result-tag">Bajo peso</h2>
          </div>
          <div className="result-container">
            <h2 className="result-tag">|</h2>
            <h2 className="result-tag">18.5</h2>
          </div>
          <div className="result-container">
            <h2 className="result-tag">Peso normal</h2>
          </div>
          <div className="result-container">
            <h2 className="result-tag">|</h2>
            <h2 className="result-tag">25</h2>
          </div>
          <div className="result-container">
            <h2 className="result-tag">Sobrepeso</h2>
          </div>
          <div className="result-container">
            <h2 className="result-tag">|</h2>
            <h2 className="result-tag">30</h2>
          </div>
          <div className="result-container">
            <h2 className="result-tag">Obesos</h2>
          </div>
        </div>
        {bmi > 0 && (
          <>
            <div className="result-text">
              <div className="result-text-title">
                <h1 className="title">{BMItext(bmi)}</h1>
              </div>

              <div className="result-text-bmi">
                <h1 className="title">{bmi ? bmi : "Calcular"}</h1>
              </div>
              <div className="result-text-comments">
                <h1>{BMIComentary(bmi).rango}</h1>
                <p>{BMIComentary(bmi).comentario}</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export { BMIResult };

/* if(bmi>0){
    console.log(bmi);
    if(bmi==18.5){
        left = { left: 'calc(25% - var(--width))' })
    }

    if(bmi>18.5 && bmi<25){
        
        left = { left: `calc(${25+((bmi-18.5)/6.5)}% - var(--width))` })
    }

    if(bmi==25){
        left = { left: 'calc(50% - 25px)' })
    }

    if(bmi>25 && bmi<30){
        console.log(50+((bmi-25)/5)*25);
        left = { left: `calc(${50+((bmi-25)/5)*25}% - 25px)` })
    }


    if(bmi==30){
        left = { left: 'calc(75% + var(--width2))' })
    }

    if(bmi>30 && bmi<35){
        left = { left: `calc(${75+((bmi-30)/5)*25}% + var(--width2))` })
    }

    if(bmi>35){
        left = { left: '94%' })
    }
} */
