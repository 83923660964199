import React from "react";
import hcards from "../../data/HelpCards.json";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Link } from "react-router-dom";

const HelpCards = () => {
  const nombreRef = React.createRef();
  const emailRef = React.createRef();

  const [nombre, setNombre] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const toggle = () => setModal(!modal);

  const handle = async (e, fileLink) => {
    e.preventDefault();

    await axios
      .post("https://formspree.io/f/meqpdnql", {
        name: nombre,
        email: email,
        message: "QUIERO UN E-BOOK-GRATIS",
      })
      .then((e) => console.log(e))
      .catch((e) => console.log(e));

    window.open(fileLink);
    toggle();
    document.getElementById("form").reset();
  };
  return (
    <div className="help-card-wrapper">
      <div
        className="uk-width-expand uk-flex uk-flex-center uk-flex-middle help-card-title"
        style={{ padding: "30px 0 0 0" }}
      >
        <h1>¿En que te puedo ayudar hoy?</h1>
      </div>

      <div
        className="uk-child-width-1-2@s uk-text-center "
        uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 100"
        repeat="true"
        uk-grid="true"
        style={{ padding: "40px", overflow: "hidden" }}
      >
        {hcards.map((hcard, index) => (
          <div uk-scrollspy-class="uk-animation-slide-bottom">
            <a
              className="uk-card uk-card-default uk-card-body uk-flex uk-flex-middle uk-flex-center uk-flex-column help-card"
              style={{ height: "300px", backgroundImage: `url(${hcard.bg})` }}
            >
              {hcard.title1 ? (
                <h1>
                  {hcard.title1}
                  <br />
                  {hcard.title2}
                </h1>
              ) : (
                <h1>{hcard.title}</h1>
              )}

              {index == 0 ? (
                <>
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle}>
                      <h2 className="title">
                        Ingresa tus datos y obten tu libro gratis
                      </h2>
                    </ModalHeader>
                    <ModalBody>
                      <form id="form">
                        <div className="uk-margin">
                          <input
                            class="uk-input"
                            placeholder="Nombre"
                            type="text"
                            name="name"
                            onChange={(e) => setNombre(e.target.value)}
                          ></input>
                        </div>
                        <div className="uk-margin">
                          <input
                            class="uk-input"
                            type="email"
                            name="_replyto"
                            placeholder="Correo"
                            onChange={(e) => setEmail(e.target.value)}
                          ></input>

                          <div className="uk-margin">
                            <button
                              className="uk-button uk-button-primary button-primary"
                              onClick={(e) => handle(e, hcard.link)}
                            >
                              Enviar
                            </button>
                          </div>
                        </div>
                      </form>
                    </ModalBody>
                  </Modal>

                  <button
                    type="button"
                    className="uk-button uk-button-default button-secondary-invert"
                    onClick={toggle}
                  >
                    "Descargar E-Book Gratis"
                  </button>
                </>
              ) : (
                <a
                  href={hcard.link && hcard.link}
                  target="_blank"
                  className="uk-button uk-button-default button-secondary-invert"
                >
                  "Comprar E-Book"
                </a>
              )}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpCards;
