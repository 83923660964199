import React from "react";
import Header from "./Header";

import "../css/sobremi.css";

const SobreMi = () => {
  return (
    <React.Fragment>
      <Header link={"/img/homepage-header.jpg"}>
        <div className="header-body uk-text-center">
          <h1>Sobre K-rel Cornejo</h1>
        </div>
      </Header>
      <div
        className="uk-width-expand"
        style={{ padding: "30px 0px", backgroundColor: "#F3EFEE" }}>
        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            Mi nombre es K-rel Cornejo, en mi niñez tuve trastornos
            alimenticios, abuse de la comida basura llevándome a tener obesidad
            a mis 8 años. En mi adolescencia empecé a entrenar por hobbies y mi
            disciplina y talento me llevo a competir en deportes de fuerza,
            convirtiéndose en mi gran pasión el rendimiento deportivo, el
            entrenamiento de la fuerza y la nutrición.
          </p>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            He dedicado gran parte de mi tiempo a la formación e investigación
            en diversos aspectos relacionados con el deporte y la mente humana,
            haciendo especial hincapié en el ámbito del acondicionamiento físico
            y el desarrollo personal, donde pretendo divulgar y ayudar en estos
            aspectos. Participó activamente en eventos deportivos relacionados
            de los ámbitos físicos y psicológicos para mantenerme actualizado.
          </p>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            He competido durante muchos años en varios deportes, Halterofilia y
            Crossfit para ser especifico, a nivel Autonómico, Nacional, y
            llegando a formar parte de la selección nacional de halterofilia en
            Nicaragua. Es a lo que me he dedicado durante más tiempo.
            <br />
            <br />
            Mis logros deportivos en competiciones de halterofilia:
          </p>
        </div>

        <div
          className="uk-child-width-expand@s uk-grid-match uk-text-center uk-grid-row-large  "
          uk-grid="true"
          style={{ padding: "40px" }}
          uk-grid="true"
          uk-scrollspy="cls:  uk-animation-slide-bottom; target: .sobremi-competitions; delay: 500;">
          {/* <div className="text-center sobremi-competitions"> 


                            <div className="uk-flex uk-flex-middle uk-flex-center">
                                <img src="img/gold-medal.svg" width="200px" alt="" uk-img="true"/>
                            </div>
                            

                            <h3>Campeonato Nacional Juvenil de Levantamiento de Pesas, (Tres medallas de Oro) Categoría 85 Kg – Campeón Nacional</h3>
                        </div>

                        <div className="text-center sobremi-competitions">


                            <div className="uk-flex uk-flex-middle uk-flex-center">
                                <img src="img/bronze-medal.svg" width="200px" alt="" uk-img="true"/>
                            </div>
                            
                            <h3>Torneo Nacional Senior Orlando Vásquez de Levantamiento de Pesas (Dos Medallas de Bronce) </h3>
                        </div>

                        <div className="text-center sobremi-competitions">
                            
                            <div className="uk-flex uk-flex-middle uk-flex-center">
                                <img src="img/4-place.svg" width="200px" alt="" uk-img="true"/>
                            </div>

                            <h3>Torneo Nacional Senior Neftaly Parrales in Memoriam (Semana Olímpica) – XXIX Jornada Deportiva Marlon Zelaya In Memoriam (Unan/Managua). Cuarto Lugar </h3>
                        </div>

                        <div className="text-center sobremi-competitions">
                            <div className="uk-flex uk-flex-middle uk-flex-center">
                                <img src="img/bronze-medal.svg" width="200px" alt="" uk-img="true"/>
                            </div>
                            <h3>Campeonato Nacional Escolar de Levantamiento de Pesas (85kg Dos bronces)</h3>
                        </div> */}

          <div>
            <div
              class="uk-card uk-card-default uk-card-body text-center sobremi-competitions uk-flex uk-flex-center uk-flex-middle "
              style={{ backgroundImage: "url('/img/achivement-card-1.jpg')" }}>
              {/* <div className="uk-flex uk-flex-middle uk-flex-center">
                                    <img src="/img/gold-medal.svg" width="200px" alt="" uk-img="true"/>
                                </div> */}

              <h3>
                Campeonato Nacional Juvenil de Levantamiento de Pesas, (Tres
                medallas de Oro) Categoría 85 Kg – Campeón Nacional
              </h3>
            </div>
          </div>

          <div>
            <div
              class="uk-card uk-card-default uk-card-body  text-center sobremi-competitions uk-flex uk-flex-center uk-flex-middle "
              style={{ backgroundImage: "url('/img/achivement-card-2.jpg')" }}>
              {/* <div className="uk-flex uk-flex-middle uk-flex-center">
                                    <img src="/img/bronze-medal.svg" width="200px" alt="" uk-img="true"/>
                                </div> */}

              <h3>
                Torneo Nacional Senior Orlando Vásquez de Levantamiento de Pesas
                (Dos Medallas de Bronce){" "}
              </h3>
            </div>
          </div>
          <div>
            <div
              class="uk-card uk-card-default uk-card-body  text-center sobremi-competitions uk-flex uk-flex-center uk-flex-middle  "
              style={{ backgroundImage: "url('/img/achivement-card-3.jpg')" }}>
              {/* <div className="uk-flex uk-flex-middle uk-flex-center">
                                    <img src="/img/regular-medal.svg" width="200px" alt="" uk-img="true"/>
                                </div> */}

              <h3>
                Torneo Nacional Senior Neftaly Parrales in Memoriam (Semana
                Olímpica) – XXIX Jornada Deportiva Marlon Zelaya In Memoriam
                (Unan/Managua). Cuarto Lugar{" "}
              </h3>
            </div>
          </div>

          <div>
            <div
              class="uk-card uk-card-default uk-card-body  text-center sobremi-competitions uk-flex uk-flex-center uk-flex-middle  "
              style={{ backgroundImage: "url('/img/achivement-card-4.jpg')" }}>
              {/* <div className="uk-flex uk-flex-middle uk-flex-center">
                                    <img src="/img/bronze-medal.svg" width="200px" alt="" uk-img="true"/>
                                </div> */}
              <h3>
                Campeonato Nacional Escolar de Levantamiento de Pesas (85kg Dos
                bronces)
              </h3>
            </div>
          </div>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            Mi interés por seguir aprendiendo e intentar dar un enfoque práctico
            a lo anterior, me hizo centrarme durante un largo tiempo en el
            acondicionamiento físico, por lo que cursé una formación profesional
            en preparación física y Nutrición deportiva y otros cursos de
            Monitor de actividades físico – deportivas.
          </p>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            Intento hacer las cosas más sencillas para la gente, que me sigue y
            a la cual le apasiona este mundo. Ya que con tanta información que
            hay, es más fácil confundirnos y desviarnos de nuestros objetivos,
            soy consciente de que en muchas ocasiones no es fácil saber cuál es
            el camino correcto, o si lo que estamos haciendo es totalmente
            eficiente. Por eso trazo una línea recta entre los objetivos que nos
            planteamos y los cuales vamos a conseguir, así como facilitar el
            trabajo a miles de personas para que puedan progresar.
          </p>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            Mi objetivo es concientizar a las personas de que con un cambio de
            actitud se puede lograr mejorar la capacidad física, emocional e
            influir significativamente en el ámbito profesional ya que la mente
            es el motor que nos ayuda a conseguir nuestros objetivos.
            <br />
            <br />
            En mi página encontrarás información real, efectiva y siempre basada
            en la EVIDENCIA científica sobre:
          </p>
        </div>

        <div
          className="uk-child-width-expand@s uk-flex uk-flex-center uk-flex-middle sobremi-p sobremi-evidence"
          uk-scrollspy="cls:  uk-animation-slide-right; target: .uk-text-center; delay: 500;"
          uk-grid="true">
          <div className="uk-text-center">
            <img src="/img/evidence-1.svg" />
            <h1>ENTRENAMIENTO & NUTRICION</h1>
          </div>

          <div className="uk-text-center">
            <img src="/img/evidence-2.svg" />
            <h1>MEJORAR TU FÍSICO & TU RENDIMIENTO & EVITAR LESIONES</h1>
          </div>

          <div className="uk-text-center">
            <img src="/img/evidence-3.svg" />
            <h1>SPORTS PSICOLOGY COACHING & MINDSET</h1>
          </div>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            Toda esta información la encontrarás de forma GRATUITA, es uno de
            los puntos clave de mi filosofía. Cada aporte lleva consigo un gran
            trabajo de investigación, síntesis, conclusión etc. muchas veces de
            meses. El objetivo es transmitir esta información de forma clara y
            sencilla para todos, añadiendo además mi experiencia como deportista
            y competidor en el alto rendimiento.
          </p>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            Todo esto me ha llevado al lugar donde estoy ahora, ser entrenador
            personal en Barcelona, pero también ser entrenador online para
            llegar a gente en todo el mundo. Si quieres puedes preguntarme en
            cualquiera de mis redes sociales. Gracias por tu tiempo y recuerda…{" "}
          </p>
        </div>

        <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p">
          <p>
            <b>
              “HAZ LO QUE HACEN LOS DEMÁS Y CONSEGUIRÁS LO QUE TODOS CONSIGUEN”
            </b>
          </p>
        </div>

        {/* <div className="uk-flex uk-flex-center uk-flex-middle sobremi-p sobremi-signature">
          <h1>
            <b>K-rel Cornejo</b>
          </h1>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default SobreMi;
