import React,{useState ,useEffect} from 'react';

import { CountUp} from 'use-count-up'

import sm from '../../data/SocialMedia.json'

const SocialMediaCounter = () => {

    let socialmedia;

    const [isCounting,setCounting]=useState(false);


    useEffect(()=>{
        socialmedia = document.querySelectorAll(".socialmedia-icons-text");
      
        createObserver();
    },[])


    const createObserver=()=>{
        let observer;

        observer= new IntersectionObserver(entries=>{
            entries.forEach(entry => {
                if (entry.intersectionRatio > 0) {
                    setCounting(true);
                } else {
                    setCounting(false);
                }
              });
        })

        socialmedia.forEach(text=>{
            observer.observe(text);
        })
    }

 /*      function handleIntersect(entries, observer) {
           setCounting(true);
      }

      function createObserver() {
        let observer;
      
        let options = {
            
        };
      
        observer = new IntersectionObserver(handleIntersect, options);
        observer.observe(boxElement);
      }

      */
      const onComplete = () => {

        return { shouldRepeat: isCounting }
      } 



    return (
        <div id="scroll" className="uk-width-expand uk-child-width-expand uk-text-center uk-flex uk-flex-middle bg-image-sticky"  style={{height:"auto",paddingTop:"50px",paddingBottom:"50px", "--bg":"url('/img/socialmedia-counter.jpg')"}} >

                <div className="bg-body   uk-child-width-expand@s uk-text-center uk-flex uk-flex-middle" uk-grid="true">
                    {
                        Object.keys(sm).map(key=>(
                            <CountUp isCounting={isCounting}  end={sm[key].followers} duration={3.2} >
                                {({value,reset})=>(
                                    <div>   
                                        <a href={`${sm[key].link}`} target="_blank" class="uk-icon-button socialmedia-icons" uk-icon={`icon:${sm[key].icon}; ratio:4`}></a>
                                        {/* <h1  className="socialmedia-icons-text">{`+${value}`}</h1> */}
                                    </div>
                                )}
                            </CountUp>
                        ))
                    }

                   
                </div>

        </div>
    );
};

export default SocialMediaCounter;