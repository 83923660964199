import React from "react";
import "../../css/composicion-corporal/RendimientoCorporal.css";

import PlatformButton from "../PlatformButton";

const RendimientoDeportivo = ({ clickGoBack }) => {
  return (
    <div
      className="option-content uk-flex-center uk-flex-middle uk-flex "
      style={{
        backgroundImage: "url(/img/rendimiento-deportivo-body-bg.jpg)",
      }}
    >
      <div
        className="option-content option-content-header uk-flex-center uk-flex-middle uk-flex"
        style={{ backgroundImage: "url(/img/sport-bg.jpg)" }}
      >
        <h1 className="title" onClick={clickGoBack}>
          Rendimiento deportivo
        </h1>
      </div>
      <div className="rendimiento-deportivo option-text uk-flex uk-flex-center uk-flex-middle">
        <div
          className="weight-loss-intro"
          style={{
            backgroundImage: "url(/img/rendimiento-deportivo-intro-bg.jpg)",
          }}
        >
          <h1 className="title">Citius – Altius – Fortius</h1>
          <h2>“Más Rapido – Más Alto – Más Fuerte”</h2>
          <p>
            Entrenar es esencialmente un acto de fe. El atleta debe creer en su
            eficacia, debe estar convencido que entrenando se tornará más fuerte
            y más rápido, que mediante la repetición sistemática de ciertos
            movimientos se harán más eficiente y sus músculos soportarán el
            esfuerzo más relajados. Debe ser un fanático del trabajo duro y
            suficientemente dedicado como para disfrutar de eso”.
          </p>

          <p>Franz Stampf</p>
        </div>

        <div
          className="uk-child-width-expand@s uk-flex-center uk-flex-middle"
          uk-grid="true"
          uk-height-match="target: > div "
          style={{ padding: "0" }}
        >
          <div
            className="rendimiento-deportivo-categories uk-flex uk-flex-middle uk-flex-column uk-flex-center"
            style={{
              backgroundImage: "url(/img/rendimiento-deportivo-1.jpg)",
            }}
          >
            <div className="uk-flex uk-flex-middle">
              <h2 className="title">
                Disciplina y paciencia es la clave de los resultados.
              </h2>
            </div>
            <p>Nada que valga la pena se consigue de manera fácil.</p>
          </div>

          <div
            className="rendimiento-deportivo-categories uk-flex uk-flex-middle uk-flex-column uk-flex-center"
            style={{
              backgroundImage: "url(/img/rendimiento-deportivo-2.jpg)",
            }}
          >
            <div className="uk-flex uk-flex-middle">
              <h2 className="title">
                Marca objetivos a corto, medio y largo plazo con fecha limite
              </h2>
            </div>
            <p>
              Se consciente de hasta dónde puede llegar tu cuerpo en relación al
              tiempo de entrenamiento. Para ello es muy importante plantearse
              unos objetivos reales en función del estado físico. Los objetivos
              deben de plantear un reto al individuo y a la vez tienen que ser
              realistas.
            </p>
          </div>

          <div
            className="rendimiento-deportivo-categories uk-flex uk-flex-middle uk-flex-column uk-flex-center"
            style={{
              backgroundImage: "url(/img/rendimiento-deportivo-3.jpg)",
            }}
          >
            <div className="uk-flex uk-flex-middle">
              <h2 className="title">Evaluar resultados</h2>
            </div>
            <p>
              «Lo que no se define no se puede medir. Lo que no se mide, no se
              puede mejorar. Lo que no se mejora, se degrada siempre.« W. T.
              Kelvin
            </p>
          </div>
        </div>

        <h2 className="title">Manten una buena hidratación</h2>
        <p>
          La{" "}
          <b>deshidratación aumenta considerablemente el riesgo de lesiones</b>{" "}
          y disminuye el rendimiento deportivo. La hidratación no solo incluye
          agua sino que hay que reponer <b>sales que se pierden con el sudor</b>
          , principalmente sodio. Sobre cantidades depende del tipo de
          actividad, duración e intensidad; una pauta general es beber unos
          600ml cada hora pero hay que adaptar este consejo para personalizarlo
          según la persona y práctica deportiva
        </p>

        <h2 className="title">Buena nutrición = Buen rendimiento</h2>
        <p>
          Tener una alimentación saludable y variada aporta los{" "}
          <b>nutrientes necesarios</b> para el correcto desempeño deportivo.
          Mantener un nivel equilibrado de carbohidratos nos permite tener los
          depósitos de glucógeno llenos (el glucógeno es la forma en la que se
          almacena la glucosa en el organismo), lo que nos dará mayor
          resistencia. Un deportista sufre mayor estrés oxidativo por lo que
          también deberemos escoger <b>alimentos nutricionalmente densos</b>,
          aquellos que contengan gran cantidad de vitaminas, minerales,
          antioxidantes (legumbres, verduras, pescados){" "}
          <b>Evitar las “calorías vacías”</b> alimentos que solo aportan
          calorías y apenas tienen nutrientes (comida rápida)
        </p>

        <h2 className="title">
          Sé optimista y haz un compromiso es contigo mismo!
        </h2>

        <p>
          La parte más importante a la hora de aumentar tu rendimiento, tanto en
          lo cotidiano como en lo deportivo. Ese COMPROMISO será el que te hará
          levantarte y entrenar cuando no sea el mejor día para ello, te
          facilitará entrenar cuando estés en un momento óptimo, y te ayudará a
          valorar todo lo conseguido.
        </p>

        <p>
          Desde la parte de nutrición, realizaremos dietas personalizadas, tras
          analizar la situación de cada persona,también realizaremos un{" "}
          <b>plan de alimentación</b> ajustado a tus características y
          necesidades. Además, contamos con un servicio de nutrición deportiva
          para llevar a nuestros deportistas a <b>cumplir sus metas</b>{" "}
          favoreciendo su recuperación y haciendo que mejoren su rendimiento.{" "}
          <br /> Desde la psicología, <b>el equilibrio</b> de nuestro cuerpo
          comienza con un buen estado de salud mental, nuestro entorno, el
          estrés, problemas de salud, todo ello puede hacer que se produzcan
          alteraciones a nivel psicológico. El trabajo del psicólogo dentro del
          equipo multidisciplinar es clave para una recuperación más rápida y
          eficaz. Psicología deportiva, para ayudar a todos nuestros deportistas
          y equipos deportivos a conseguir sus metas,{" "}
          <b>mejorar su rendimiento</b> y potenciar el trabajo en equipo.
        </p>
        <p>
          Para ayudar a todos nuestros deportistas y equipos deportivos a
          conseguir sus metas, <b>mejorar su rendimiento</b> y potenciar el
          trabajo en equipo.
        </p>

        <div className="rendimiento-deportivo-platform uk-flex uk-flex-center uk-flex-middle uk-flex-column">
          <h1 className="title">
            ¿Me permites informarte sobre como hacer bien las cosas?
          </h1>
          <PlatformButton />
        </div>
      </div>
    </div>
  );
};

export { RendimientoDeportivo };
