import React,{useState} from 'react';


const Timer = ({tabata}) => {

    const [inProp, setInProp] = useState(true);
    return (
        <div className="tabata-timer-content uk-text-center">
            <div className="tabata-timer-round uk-flex uk-flex-center uk-flex-middle">
                <h1>{`ROUND ${tabata.rounds}`}</h1>
            </div>
            
            <div className="uk-width-expand uk-flex uk-flex-center uk-flex-middle" >
                <div className="tabata-timer-timer  uk-flex uk-flex-center uk-flex-middle" style={{backgroundColor:tabata.isStarting? "green":!tabata.work && 'red'}}>
                        {tabata.isStarting? <h1>{tabata.countdownString}</h1>: <h1>
                            {tabata.work?tabata.workString: tabata.restString}
                        </h1>}

                </div>
            </div>

            <div className="tabata-timer-mov uk-flex uk-flex-center uk-flex-middle">
                {
                    tabata.isStarted && <h1>{tabata.work?'WORK!':"REST!"}</h1>
                }
            </div>

            {/* <div>
                <button onClick={setSeconds} class="uk-button uk-button-primary">Primary</button>
            </div> */}
        </div>
    );
};

export {Timer};