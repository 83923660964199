import React from "react";
import Header from "./Header";
import books from "../data/RecomendedBooks.json";
import "../css/RecomendedBooks.css";
const RecomendedBooks = () => {
  return (
    <div>
      <Header link={"/img/homepage-header.jpg"}>
        <div className="header-body uk-text-center">
          <h1>Libros Recomendados</h1>
        </div>
      </Header>

      <h1 className="title title-book">MIS EBOOKS</h1>
      <div
        className="uk-child-width-1-1@s uk-child-width-1-4@m books"
        uk-scrollspy="target: > div; cls: uk-animation-slide-left; delay: 100; repeat: true;"
        uk-grid={{ masonry: "true" }}
        style={{ margin: "50px", overflow: "hidden" }}>
        {Object.keys(books).map(
          (key, index) =>
            index < 4  && (
              <div key={key}>
                <div
                  class="uk-card uk-card-default uk-card-body book-card"
                  style={{ padding: "0" }}>
                  <img width="100%" src={`${books[key].cover}`} />
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <div>
                      <h3 class="uk-card-title">{books[key].title}</h3>
                      <p>{books[key].author} </p>
                    </div>
                  </div>
                  {/* <h3 class="uk-card-title">Default</h3>
                              <p>Lorem ipsum <a href="#">dolor</a> sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
              </div>
            )
        )}
      </div>
      <h1 className="title title-book">OTRAS RECOMENDACIONES</h1>
      <div
        className="uk-child-width-1-1@s uk-child-width-1-4@m books"
        uk-scrollspy="target: > div; cls: uk-animation-slide-left; delay: 100; repeat: true;"
        uk-grid={{ masonry: "true" }}
        style={{ margin: "50px", overflow: "hidden" }}>
        {Object.keys(books).map(
          (key, index) =>
            index > 3 && (
              <div key={key}>
                <div
                  class="uk-card uk-card-default uk-card-body book-card"
                  style={{ padding: "0" }}>
                  <img width="100%" src={`${books[key].cover}`} />
                  <div className="uk-flex uk-flex-middle uk-flex-center">
                    <div>
                      <h3 class="uk-card-title">{books[key].title}</h3>
                      <p>{books[key].author} </p>
                    </div>
                  </div>
                  {/* <h3 class="uk-card-title">Default</h3>
                            <p>Lorem ipsum <a href="#">dolor</a> sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default RecomendedBooks;
