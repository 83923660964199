import React from "react";
import "../css/Contacto.css";
import sm from "../data/SocialMedia.json";
import axios from "axios";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const Contacto = () => {
  const nombreRef = React.createRef();
  const emailRef = React.createRef();
  const messagRef = React.createRef();

  const handle = async (e) => {
    e.preventDefault();

    await axios
      .post("https://formspree.io/f/meqpdnql", {
        name: nombreRef.current.value,
        email: emailRef.current.value,
        message: messagRef.current.value,
      })
      .then((e) => console.log(e));

    document.getElementById("form").reset();
  };

  return (
    <div
      className="contact-wrapper uk-flex uk-flex-center uk-flex-top uk-child-width-expand@s uk-grid-match"
      uk-grid="true"
      style={{ backgroundImage: "url(/img/contact-bg.jpg" }}
    >
      <div className="contact-form">
        <div className="uk-text-center title">
          <h1 className="title">¿EN QUE PUEDO AYUDARTE?</h1>
        </div>
        <form id="form" onSubmit={handle}>
          <div className="uk-margin">
            <input
              className="uk-input contact-input uk-form-blank"
              type="text"
              name="name"
              placeholder="Nombre"
              ref={nombreRef}
            ></input>
          </div>

          <div className="uk-margin">
            <input
              className="uk-input contact-input uk-form-blank"
              type="email"
              name="_replyto"
              placeholder="Email"
              ref={emailRef}
            />
          </div>

          <div className="uk-margin">
            <textarea
              class="uk-textarea contact-input uk-form-blank"
              rows="5"
              placeholder="Mensaje"
              name="message"
              ref={messagRef}
            ></textarea>
          </div>
          <input type="hidden" name="_next" value="https://k-relcornejo.com/" />
          <div className="uk-margin uk-text-center">
            <input
              className="uk-button uk-button-primary button-primary"
              type="submit"
            />
          </div>
        </form>
      </div>
      <div className="contact-info">
        <div className="contact-info-socialmedia">
          <div className="uk-text-center title">
            <h2 className="title">Conecta con K-REL CORNEJO</h2>
          </div>
          <div className="uk-flex uk-flex-center uk-flex-top">
            {sm.map((socialmedia) => (
              <a
                href={`${socialmedia.link}`}
                target="_blank"
                className="uk-icon-button contact-socialmedia-icons"
                uk-icon={`icon:${socialmedia.icon}; ratio:2`}
              ></a>
            ))}
          </div>
        </div>
        <div className="contact-info-personal uk-text-center">
          {/* <h2>K-rel Cornejo</h2> */}
          <h3>
            <img width="20px" src="/img/email.svg" /> info@k-relcornejo.com
          </h3>
          <h2>
            <span></span>+34 688 392 497
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Contacto;
