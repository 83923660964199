import React, { useState } from "react";
import { Switch } from "react-router-dom";
import useFormSlider from "../hooks/useFormSlider/useFormSlider";
import {
  Aintermitente,
  DCetogenica,
  DMediterranea,
  DPaleo,
  DZona,
  WWatcher,
} from "./alimentacion-inteligente";
const AlimentacionInteligente = () => {
  const { FormSlider, clickGoNext, clickGoBack } = useFormSlider(2);

  const AINTERMITENTE = 1;
  const DPALEO = 2;
  const DZONA = 3;
  const DCETOGENICA = 4;
  const DMEDITERRANEA = 5;
  const WWATCHER = 6;
  /*   const VEGETARIANA = 7;
  const VEGANA = 8;
  const SREQUERIMIENTOS = 9; */

  const [option, setOption] = useState(0);

  const setSelection = (select) => {
    setOption(select);
    clickGoNext();
  };

  return (
    <div className="option-wrapper">
      <FormSlider>
        <div className="option-content uk-flex-center uk-flex-middle uk-flex ">
          <h1 className="title">Seleccionar tu sexo </h1>
          <div
            className="uk-grid-large uk-child-width-expand@s uk-flex uk-flex-center"
            uk-grid="true">
            <div className="single-option-wrapper">
              <div
                onClick={clickGoNext}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-woman.jpg)" }}>
                <h1 className="title">Mujer</h1>
              </div>
            </div>
            <div className="single-option-wrapper">
              <div
                onClick={clickGoNext}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-men.jpg)" }}>
                <h1 className="title">Hombre</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="option-content uk-flex-center uk-flex-middle uk-flex uk-overflow-hidden">
          <h1 className="title">
            <img src="/img/previous.svg" onClick={clickGoBack} /> ¿Cual es tu
            Dieta?
          </h1>
          <div
            className="uk-child-width-1-3@m uk-child-width-1-1@s "
            uk-grid="true">
            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(AINTERMITENTE)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/ayuno-intermitente-bg.jfif)" }}>
                <h1 className="title">Ayuno Intermitente</h1>
              </div>
            </div>

            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(DPALEO)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/dieta-paleo.jpg)" }}>
                <h1 className="title">Dieta Paleo</h1>
              </div>
            </div>

            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(DZONA)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-men.jpg)" }}>
                <h1 className="title">Dieta de la zona</h1>
              </div>
            </div>
{/*             <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(DCETOGENICA)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-woman.jpg)" }}>
                <h1 className="title"> Dieta Cetogenica</h1>
              </div>
            </div>

            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(DMEDITERRANEA)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/dieta-mediterranea-bg.jfif)" }}>
                <h1 className="title">Dieta Mediterranea</h1>
              </div>
            </div>

            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(WWATCHER)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/weight-watcher-bg.jfif)" }}>
                <h1 className="title">Weight Watcher</h1>
              </div>
            </div> */}

            {/*             <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(VEGETARIANA)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-woman.jpg)" }}>
                <h1 className="title">Vegetariana</h1>
              </div>
            </div>

            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(VEGANA)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-men.jpg)" }}>
                <h1 className="title">Vegana</h1>
              </div>
            </div>

            <div className="single-option-wrapper">
              <div
                onClick={() => setSelection(SREQUERIMIENTOS)}
                className="option uk-flex-middle uk-flex uk-flex-center"
                style={{ backgroundImage: "url(/img/gender-men.jpg)" }}>
                <h1 className="title">Sin requerimientos.</h1>
              </div>
            </div> */}
          </div>
        </div>

        {option === AINTERMITENTE && <Aintermitente clickGoBack={clickGoBack} />}
        {option === DPALEO && <DPaleo clickGoBack={clickGoBack} />}
        {option === DZONA && <DZona clickGoBack={clickGoBack} />}
        {option === DCETOGENICA && <DCetogenica clickGoBack={clickGoBack} />}
        {option === DMEDITERRANEA && <DMediterranea clickGoBack={clickGoBack} />}
        {option === WWATCHER && <WWatcher clickGoBack={clickGoBack} />}
      </FormSlider>
    </div>
  );
};

export default AlimentacionInteligente;
